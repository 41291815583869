import React from "react";
import { Modal } from "antd";
import { QuestionModalProps } from "../../../constants/interfaces/question";
import QuestionForm from "../../Forms/QuestionForm/questionForm";
import { htmlToString } from "../../../utils/helpers";
import { TagInterface } from "../../../constants/interfaces/tag";

const QuestionModal: React.FC<QuestionModalProps> = ({
  isViewing,
  isModalOpen,
  isAddQuestion,
  selectedQuestion,
  isEditQuestion,
  tags,
  handleModalToggle,
  handleSubmit,
}) => {
  return (
    <Modal
      title={
        isViewing
          ? `Question Details`
          : isAddQuestion
            ? "Add New Question"
            : `Edit Question`
      }
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
    >
      {isViewing && (
        <div className="question-details">
          <p>
            <strong>Question:</strong>{" "}
            {htmlToString(selectedQuestion?.quesText)}
          </p>
          <p>
            <strong>Options:</strong> {selectedQuestion?.quesOptions.join(", ")}
          </p>
          <p>
            <strong>Answer:</strong> {selectedQuestion?.answer}
          </p>
          <p>
            <strong>Type:</strong> {selectedQuestion?.quesType}
          </p>
          <p>
            <strong>Tags:</strong>
            {selectedQuestion?.quesTags?.map(
              (tag: TagInterface, index: number) => {
                const isLastTag =
                  index === (selectedQuestion?.quesTags?.length && selectedQuestion?.quesTags?.length - 1);
                return isLastTag ? tag.name : ` ${tag.name}, `;
              }
            )}
          </p>
        </div>
      )}
      {(isAddQuestion || selectedQuestion) && !isViewing && (
        <QuestionForm
          onSubmit={handleSubmit}
          onCancel={handleModalToggle}
          tags={tags}
          initialValues={isAddQuestion ? {} : selectedQuestion}
          isEditQuestion={isEditQuestion}
        />
      )}
    </Modal>
  );
};

export default QuestionModal;
