import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InstituteState } from "../../constants/interfaces/institute";
import { PaginationInterface } from "../../constants/interfaces/common";

const initialState: InstituteState = {
    instituteData: null,
    isCollapsed: false,
    isModalOpen: false,
    selectedInstitute: null,
    isViewing: false,
    isAddInstitute: false,
    paginationData: undefined,
  };
  
  const institutesSlice = createSlice({
    name: 'institutes',
    initialState,
    reducers: {
      setInstitutes: (state, action: PayloadAction<any[]>) => {
        state.instituteData = action.payload;
      },
      toggleCollapsed: (state, action: PayloadAction<boolean>) => {
        state.isCollapsed = action.payload;
      },
      toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
        state.isModalOpen =  action.payload;
      },
      setSelectedInstitute: (state, action: PayloadAction<any | null>) => {
        state.selectedInstitute = action.payload;
      },
      setIsViewing: (state, action: PayloadAction<boolean>) => {
        state.isViewing = action.payload;
      },
      removeInstitute: (state, action: PayloadAction<string>) => {
        state.instituteData = state.instituteData?.filter(
          (institute) => institute._id !== action.payload
        );
      },
      setIsAddInstitute: (state, action: PayloadAction<boolean>) => {
        state.isAddInstitute = action.payload;
      },
      setPagination: (state, action: PayloadAction<PaginationInterface>) => {
        state.paginationData = action.payload;
      }
    },
  });
  
  export const { toggleCollapsed, toggleIsModalOpen, setSelectedInstitute, setIsViewing, removeInstitute, setIsAddInstitute, setInstitutes, setPagination } = institutesSlice.actions;
  
  export default institutesSlice.reducer;