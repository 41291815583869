import React, { useEffect, useState } from "react";
import InstructionHeader from "../../components/Header/Instruction/InstructionHeader";
import TestIntroductionCard from "../../components/Cards/Introduction/TestIntroductionCard";
import './_testInstruction.scss';
import TestInstructionCard from "../../components/Cards/Instruction/TestInstructionCard";
import StartTestCard from "../../components/Cards/Start/startTestCard";
import { TestInstructionProps } from "../../constants/interfaces/test";
import { detectIncognitoMode, getAllDeviceDetails } from "../../utils/helpers";
import { message } from "antd";
import moment from "moment";

const TestInstruction: React.FC<TestInstructionProps> = ({testData, startTest, openFullScreen}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isStart, setIsStart] = useState(false);

  useEffect (() => {
    if (testData) {
    const { startTime, endTime } = testData;
    const currentTime = moment();
    if (
      moment(startTime).isBefore(currentTime) &&
      moment(endTime).isAfter(currentTime)
    ) {
      setIsStart(true);
    } else {
      setIsStart(false);
    }
  }
  }, [setIsStart, testData])

  const handleStartTest = async () => {
    const deviceDetails = await getAllDeviceDetails();
    if (deviceDetails?.browserInfo?.name !== "Chrome") {
      return message.error("Requirements Not Met! Please use Chrome Browser!");
    }
    const isPrivate = await detectIncognitoMode();
    if (isPrivate) {
      return message.error("Requirements Not Met! Do not use incognito mode.");
    }
    
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      startTest();
      setIsStart(true);
      openFullScreen();
    } catch (error) {
      return message.error(
        "Access to camera and microphone is required. Please allow access and try again."
      );
    }
  };

  return (
    <div className="test-instruction">
      <div className="header">
        <InstructionHeader />
      </div>
      <div className="body">
        <div className="body-left">
          <div>
            <TestIntroductionCard sections={testData?.sections} />
          </div>
          <div>
            <TestInstructionCard sections={testData?.sections} />
          </div>
        </div>
        <div className="body-right">
          <StartTestCard
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            endTime={testData?.endTime}
            startEnabled={isStart}
            handleStartTest={handleStartTest}
          />
        </div>
      </div>
    </div>
  );
};

export default TestInstruction;