import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from './redux/store';
import { Roles, RedirectRoutes } from './constants/enums/enums';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  allowedRoles: string[];
  exact?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, allowedRoles, ...rest }) => {
  const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const hasAccess = allowedRoles.length === 0 || allowedRoles.some(role => user?.role?.includes(role));

  const navigateTo = user?.role === Roles?.CANDIDATE ? RedirectRoutes.CANDIDATE : RedirectRoutes.ADMIN;

  return isLoggedIn && hasAccess ? (
    <Component {...rest} />
  ) : (
    <Navigate to={navigateTo} />
  );
};

export default PrivateRoute;
