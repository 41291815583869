import React from "react";
import { Modal } from "antd";
import { InstituteModalProps } from "../../../constants/interfaces/institute";
import InstituteForm from "../../Forms/InstituteForm/instituteForm";

const InstituteModal: React.FC<InstituteModalProps> = ({
  isViewing,
  isModalOpen,
  isAddInstitute,
  selectedInstitute,
  handleModalToggle,
  handleSubmit,
}) => {

  return (
    <Modal
      title={
        isViewing
          ? `Institute Details - ${selectedInstitute?.name}`
          : "Add New Institute"
      }
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
    >
      {isViewing && (
        <div className="institute-details">
          <p>
            <strong>Name:</strong> {selectedInstitute?.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedInstitute?.email}
          </p>
          <p>
            <strong>Phone:</strong> {selectedInstitute?.phone}
          </p>
          <p>
            <strong>Student Count:</strong> {selectedInstitute?.studentCount}
          </p>
          {selectedInstitute?.address && (
            <p>
              <strong>Address:</strong> {selectedInstitute?.address?.address}
            </p>
          )}
        </div>
      )}

      {(isAddInstitute) && (
        <InstituteForm
          onSubmit={handleSubmit}
          onCancel={handleModalToggle}
        />
      )}
    </Modal>
  );
};

export default InstituteModal;