import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PaginationInterface } from "../../constants/interfaces/common";
import { CandidateTestState } from "../../constants/interfaces/candidateTest";
import { TestInterface } from "../../constants/interfaces/test";

const initialState: CandidateTestState = {
    candidateTestData: [],
    selectedCandidateTest: null,
    isCollapsed: false,
  };
  
  const candidateTestSlice = createSlice({
    name: 'candidateTest',
    initialState,
    reducers: {
      setCandidateTestData: (state, action: PayloadAction<TestInterface[]>) => {
        state.candidateTestData = action.payload;
      },
      toggleCollapsed: (state, action: PayloadAction<boolean>) => {
        state.isCollapsed = action.payload;
      },
    //   toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
    //     state.isModalOpen =  action.payload;
    //   },
      setSelectedCandidateTest: (state, action: PayloadAction<TestInterface>) => {
        state.selectedCandidateTest = action.payload;
      },
    //   setIsViewing: (state, action: PayloadAction<boolean>) => {
    //     state.isViewing = action.payload;
    //   },
    //   removeInstitute: (state, action: PayloadAction<string>) => {
    //     state.instituteData = state.instituteData?.filter(
    //       (institute) => institute._id !== action.payload
    //     );
    //   },
    //   setIsAddInstitute: (state, action: PayloadAction<boolean>) => {
    //     state.isAddInstitute = action.payload;
    //   },
      setPagination: (state, action: PayloadAction<PaginationInterface>) => {
        state.paginationData = action.payload;
      }
    },
  });
  
  export const { setSelectedCandidateTest, setCandidateTestData, setPagination, toggleCollapsed } = candidateTestSlice.actions;
  
  export default candidateTestSlice.reducer;