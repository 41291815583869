import React, { useEffect, useState } from "react";
import { Menu, Tooltip } from "antd";
import {
  HomeOutlined,
  QuestionCircleOutlined,
  FileOutlined,
  InsertRowLeftOutlined,
  InsertRowRightOutlined,
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./_sidebar.scss";
import userLogo from "../../assets/icons/user.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { logout } from "../../redux/slice/auth";
import { Roles } from "../../constants/enums/enums";
import { ADMIN_LOGOUT, CANDIDATE_LOGOUT } from "../../constants/endpoints/auth";
import { logoutService } from "../../services/auth";

const PrimarySidebar: React.FC = () => {
  const [collapsed, setIsCollapsed] = useState(window.innerWidth < 1130);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 1130);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlelogout = async () => {
    await logoutService(
      user?.role === Roles.CANDIDATE ? CANDIDATE_LOGOUT : ADMIN_LOGOUT
    );
    dispatch(logout());
    localStorage.clear();
    console.log('logged out');
    navigate("/login");
  };

  const location = useLocation();
  const selectedKey = location.pathname;

  const menuItems = [
    ...(user?.role === Roles.SUPER_ADMIN ||
    user?.role === Roles.ORG_ADMIN ||
    user?.role === Roles.INS_ADMIN
      ? [
          {
            key: "/dashboard",
            icon: <HomeOutlined />,
            label: <Link to="/dashboard">{!collapsed && "Dashboard"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.CANDIDATE
      ? [
          {
            key: "/c/tests",
            icon: <EditOutlined />,
            label: <Link to="/c/tests">{!collapsed && "Tests"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.CANDIDATE
      ? [
          {
            key: "/c/profile",
            icon: <UserOutlined />,
            label: <Link to="/c/profile">{!collapsed && "Profile"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN ||
    user?.role === Roles.ORG_ADMIN ||
    user?.role === Roles.INS_ADMIN
      ? [
          {
            key: "/candidates",
            icon: <UserOutlined />,
            label: <Link to="/candidates">{!collapsed && "Candidates"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/tests",
            icon: <EditOutlined />,
            label: <Link to="/tests">{!collapsed && "Tests"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/questions",
            icon: <QuestionCircleOutlined />,
            label: <Link to="/questions">{!collapsed && "Questions"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/tags",
            icon: <TagOutlined />,
            label: <Link to="/tags">{!collapsed && "Tags"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/reports",
            icon: <FileOutlined />,
            label: <Link to="/reports">{!collapsed && "Reports"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/organizations",
            icon: <InsertRowLeftOutlined />,
            label: (
              <Link to="/organizations">{!collapsed && "Organizations"}</Link>
            ),
          },
          {
            key: "/institutes",
            icon: <InsertRowRightOutlined />,
            label: <Link to="/institutes">{!collapsed && "Institutes"}</Link>,
          },
        ]
      : []),
    ...(user?.role === Roles.SUPER_ADMIN
      ? [
          {
            key: "/settings",
            icon: <SettingOutlined />,
            label: <Link to="/settings">{!collapsed && "Settings"}</Link>,
          },
        ]
      : []),
  ];

  return (
    <div className={`primary-sidebar ${collapsed ? "collapsed" : ""}`}>
      <div className="profile-section">
        <img src={userLogo} alt="Profile" className="profile-picture" />
        {!collapsed && (
          <div className="profile-info">
          <Tooltip title={user?.firstName + " " + user?.lastName}>  
            <h3 className="ellipsis">
              {user?.firstName + " " + user?.lastName}
            </h3>
          </Tooltip>
          <Tooltip title={user?.role === Roles.CANDIDATE ? <p>{user?.institute?.name || user?.organization?.name}</p> : <p>{user?.role}</p>}>
            <div className="ellipsis">
              {user?.role === Roles.CANDIDATE ? <p>{user?.institute?.name || user?.organization?.name}</p> : <p>{user?.role}</p>}
            </div>
          </Tooltip>
          </div>
        )}
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        style={{ borderRight: 0 }}
        inlineCollapsed={collapsed}
        items={menuItems}
      />
      <div className="footer-sidebar" onClick={handlelogout}>
        <div className="logout-button">
          <LogoutOutlined />
        </div>
        <div className="text">{!collapsed && "Log Out"}</div>
      </div>
    </div>
  );
};

export default PrimarySidebar;