import { ParamObj } from "../constants/interfaces/common";
import { TestInterface } from "../constants/interfaces/test";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const testAxios = createAxiosInstance('TEST');

export const addTestService = async (url: string, payload: TestInterface) => {
    try {
        const response = await testAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const getAllTestService = async (url: string, paramObj: ParamObj) => {
    try {
        let getUrl = url;
        if (paramObj) getUrl = `${url}${makeQueryParam(paramObj)}`;
        const response = await testAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const getAllExecutedTestService = async (url: string, paramObj: ParamObj) => {
    try {
        let getUrl = url;
        if (paramObj) getUrl = `${url}${makeQueryParam(paramObj)}`;
        const response = await testAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteTestService = async (url: string) => {
    try {
        const response = await testAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const editTestService = async (url: string, payload: any) => {
    try {
        const response = await testAxios.patch(url, payload);
        return response?.data;
    } catch (err) {}
}

export const getAllCandidateTestService = async (url: string, paramObj: ParamObj) => {
    try {
        let getUrl = url;
        if (paramObj) getUrl = `${url}${makeQueryParam(paramObj)}`;
        const response = await testAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const getCandiateTestService = async (url: string, paramObj: ParamObj) => {
    try {
        let getUrl = url;
        if (paramObj) getUrl = `${url}${makeQueryParam(paramObj)}`;
        const response = await testAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}