import React from "react";
import { Button, Result } from "antd";
import "./_thankyou.scss";
import { useNavigate } from "react-router-dom";

const ThankYou: React.FC = () => {
    const navigate = useNavigate();
    const closeFullScreen = () => {
        if (
          document.fullscreenElement || 
          (document as any).webkitFullscreenElement || 
          (document as any).mozFullScreenElement || 
          (document as any).msFullscreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
          } else if ((document as any).mozCancelFullScreen) {
            (document as any).mozCancelFullScreen();
          } else if ((document as any).msExitFullscreen) {
            (document as any).msExitFullscreen();
          }
        } else {
          console.log("Not in full-screen mode.");
        }
        navigate('/c/tests');
      };

  return (
    <div className="thankyou-container">
      <Result
        status="success"
        title="Thank You!"
        subTitle={
          <>
            Your test has been successfully submitted! <br />
            You can now exit full-screen mode and leave the test environment. If required, please press the ESC key to exit the full-screen mode.
          </>
        }
        extra={[
          <Button type="primary" key="exit" onClick={closeFullScreen}>
            Exit Screen
          </Button>,
        ]}
      />
    </div>
  );
};

export default ThankYou;