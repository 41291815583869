import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_tag.scss";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Roles, TabStatus } from "../../constants/enums/enums";
import { debounce } from "lodash";

import { removeTag, setIsAddTag, setSelectedTag, setTags, toggleCollapsed, toggleIsModalOpen, setPagination } from "../../redux/slice/tags";
import {
  GET_ALL_TAGS_ADMIN,
  DELETE_TAG_ADMIN,
  ADD_TAG_ADMIN,
} from "../../constants/endpoints/tag";
import { addTagService, deleteTagService, getAllTagService } from "../../services/tag";
import { Button, Input, Modal, Segmented, Table, message } from "antd";
import { TagInterface } from "../../constants/interfaces/tag";

import { DeleteFilled } from "@ant-design/icons"
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import TagModal from "../../components/Modals/TagModal/AddTagModal";
import useQueryParams from "../../utils/useQueryParams";

const Tags: React.FC = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { isCollapsed, isAddTag, isModalOpen, tagData, paginationData } = useSelector((state: RootState) => state.tags);
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllTags = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;
  
        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');
  
        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };
  
        const response = await getAllTagService(GET_ALL_TAGS_ADMIN, paramObj);
        if (response.success) {
          if (q === "" || !q) {
          dispatch(setTags(response?.data?.data));
            dispatch(setPagination({
              totalCount: response?.data?.pagination?.totalCount,
              currentPage,
              pageSize: limit,
            }));
          } else {
            dispatch(setTags(response?.data));
            dispatch(setPagination({
              totalCount: response?.data?.length,
              currentPage: 1,
              pageSize: 50
            }))
          }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 1000),
    [dispatch]
  );
  
    useEffect(() => {
      if (
        !apiCalled.current &&
        (user?.role === Roles.SUPER_ADMIN || Roles.ORG_ADMIN || Roles.INS_ADMIN)
      ) {
        debouncedGetAllTags();
        apiCalled.current = true;
      }

      const handleResize = () => {
        if(window.innerWidth < 1130) {
          dispatch(toggleCollapsed(true));
        } else {
          dispatch(toggleCollapsed(false));
        }
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [user, dispatch, debouncedGetAllTags]);

    const handleTabChange = (key: string) => {
        removeQueryParam("page");
        debouncedGetAllTags("", key, 1, 10, true);
      }; 

      const handleTableChange = (currentPage: number) => {
        setIsLoader(true)
        updateQueryParam("page", `${currentPage}`);
        debouncedGetAllTags(searchTerm, TabStatus.ALL, currentPage, paginationData?.pageSize, true);
      };  
      
      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchTerm(value);
        debouncedGetAllTags(value);
      };  

      const handleAddTag = () => {
        dispatch(setSelectedTag(null));
        dispatch(setIsAddTag(true));
        dispatch(toggleIsModalOpen(true));
      };
      
            const handleDelete = async (tag: TagInterface) => {
              try {
                setIsLoader(true);
                const response = await deleteTagService(
                  `${DELETE_TAG_ADMIN.replace(":id", tag._id)}`
                );
          
                if (response?.success) {
                  dispatch(removeTag(tag._id));
                  message.success("tag removed successfully!");
                }
                setIsLoader(false);
              } catch (err) {
                setIsLoader(false);
                console.error("Failed to delete tag", err);
              }
            };
      
      const confirmDelete = (tag: TagInterface) => {
        Modal.confirm({
          title: "Are you sure you want to delete this tag?",
          onOk: () => handleDelete(tag),
          okText: "Yes",
          cancelText: "No",
          centered: true,
        });
      };

      const handleModalToggle = () => {
        isAddTag && dispatch(setIsAddTag(false));
        dispatch(toggleIsModalOpen(false));
      };


  const handleSubmit = async (values: TagInterface) => {
    try {
      setIsLoader(true);
      const response = await addTagService(ADD_TAG_ADMIN, values);
      if (response.success) {
        message.success('Tag added successfully!')
        debouncedGetAllTags();
        handleModalToggle();
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
    }
  };


      const columns = [
        {
          title: "S.No.",
          key: "index",
          render: (_: any, __: any, index: number) => index + 1,
        },
        {
          title: "Name",
          key: "name",
          render: (tagData: TagInterface) =>
            tagData.name,
        },
        {
          title: "Actions",
          key: "actions",
          render: (tagData: TagInterface) => (
            <div className="action-section">
              <Button type="link" onClick={() => confirmDelete(tagData)}>
              <DeleteFilled style={{ fontSize: "18px", color: 'red' }} />
              </Button>
            </div>
          ),
        },
      ];  

  return (
    <div>
    {user?.role === Roles.SUPER_ADMIN ? (
      <div className={`tag ${isCollapsed ? "collapsed" : "open"}`}>
        <Header title={"Tags"} />
        <div className="head">
          <div>
            <Segmented
              defaultValue={TabStatus.ALL}
              onChange={handleTabChange}
              options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
            />
          </div>
          <div className="head-right">
            <Input
              className="antd-search-input-custom"
              placeholder="Search tags"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <Button
              type="primary"
              className="button"
              onClick={handleAddTag}
            >
              Add Tag
            </Button>
          </div>
        </div>
        
        {(<div className="table">
          <Table
            dataSource={tagData || []}
            columns={columns}
            rowKey={(record) => record._id}
            loading={!tagData || isLoader}
            pagination={{
              current: paginationData?.currentPage,
              pageSize: paginationData?.pageSize,
              total: paginationData?.totalCount || 0, 
              onChange: handleTableChange,
            }}
            scroll={{ y: '61dvh' }}
          />
        </div>)}
      </div>
    ) : (
      <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
        <img src={UnauthorizedImage} alt="Unauthorized" />
      </div>
    )}
    <TagModal
      isModalOpen={isModalOpen}
      isAddTag={isAddTag}
      handleModalToggle={handleModalToggle}
      handleSubmit={handleSubmit}
      tagData={tagData}
    />
  </div>
  );
};

export default Tags;
