import { ParamObj } from "../constants/interfaces/common";
import { GetSectionQuestion, GetTestSection, SaveTestAnswer, StartTestSession, SubmitTestSession } from "../constants/interfaces/testSession";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const testSessionAxios = createAxiosInstance('TEST_SESSION');

export const getSectionService = async (url: string, payload?: GetTestSection) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await testSessionAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const getSectionQuestionService = async (url: string, payload?: GetSectionQuestion) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await testSessionAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const startTestSessionService = async (url: string, payload: StartTestSession) => {
    try {
        const response = await testSessionAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const submitTestSessionService = async (url: string, payload: SubmitTestSession) => {
    try {
        const response = await testSessionAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const saveTestAnswerService = async (url: string, payload: SaveTestAnswer) => {
    try {
        const response = await testSessionAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}


