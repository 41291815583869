import React from "react";
import './_startTestCard.scss';
import { Button, Checkbox } from "antd";
import { InfoCircleTwoTone } from '@ant-design/icons';
import { StartTestCardProps } from "../../../constants/interfaces/test";
import { formatDateInHrMinSec } from "../../../utils/helpers";

const StartTestCard: React.FC<StartTestCardProps> = ({ setIsChecked, handleStartTest, isChecked, endTime, startEnabled }) => {
  const endTimeFormatted = endTime && formatDateInHrMinSec(endTime);

    return (
        <div className="start-test-card">
            <div className="card-header">
                <h2>Start Your Test Here!</h2>
            </div>
            <div className="card-body">
                <div className="term-box" onClick={() => setIsChecked && setIsChecked(!isChecked)}>
                    <Checkbox checked={isChecked} onChange={() => setIsChecked && setIsChecked(!isChecked)} />
                    <span className="term-line">I have read & accept all the instructions of the test!</span>
                </div>
                <div className="expiry-box">
                    <InfoCircleTwoTone/><span className="expiry-line"> The invitation will expire on <span className="date">{endTimeFormatted}</span></span>
                </div>
                <Button 
                className="test-start-button" 
                type="primary" 
                onClick={handleStartTest}
                disabled={!isChecked || !startEnabled}
                >
                    <span className={`start-button-text ${(!isChecked || !startEnabled)&& 'disabled-color'}`}>START THE TEST</span>
                </Button>
            </div>
        </div>
    );
}

export default StartTestCard;