import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import "./_organizationForm.scss";
import { OrganizationFormProps } from "../../../constants/interfaces/organization";
import { getIndianStates } from "../../../utils/helpers";

const OrganizationForm: React.FC<OrganizationFormProps> = ({
  onSubmit,
  onCancel,
  initialValues
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
      form.resetFields();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Form form={form} layout="vertical" className="organization-form">
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input the name!" }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input the email!" },
          { type: "email", message: "Please enter a valid email!" },
        ]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone"
        rules={[
          { required: true, message: "Please input the phone number!" },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid 10-digit phone number!",
          },
        ]}
      >
        <Input placeholder="Enter phone number" />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Address"
            name={['address', 'address']}
            rules={[]}
          >
            <Input placeholder="Enter address" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Landmark"
            name={['address', 'landmark']}
            rules={[]}
          >
            <Input placeholder="Enter landmark" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="City"
            name={['address', 'city']}
            rules={[]}
          >
            <Input placeholder="Enter city" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="State"
            name={['address', 'state']}
            rules={[]}
          >
             <Select placeholder="Select state">
              {getIndianStates().map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Pincode"
            name={['address', 'pincode']}
            rules={[]}
          >
            <Input placeholder="Enter pincode" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Employee Count"
            name="employeeCount"
            rules={[]}
          >
            <Input placeholder="Enter employee count" />
          </Form.Item>
        </Col>
      </Row>

      <div className="form-actions">
        <Button className="cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" className="submit-button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default OrganizationForm;