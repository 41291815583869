import React from "react";
import "./_testIntroductionCard.scss";
import { Divider } from "antd";
import { TestIntroductionCardProps } from "../../../constants/interfaces/test";

const TestIntroductionCard: React.FC<TestIntroductionCardProps> = ({
  sections
}) => {
  const formattedTime =
    sections?.reduce((acc, section) => acc + (section.totalTime || 0), 0) || 0;
  const displayTime = `${Math.floor(formattedTime / 3600) > 0 ? `${Math.floor(formattedTime / 3600)}hr ` : ""}${Math.floor((formattedTime % 3600) / 60)}min`;

  return (
    <div className="introduction-card">
      <div className="card-header">
        <h2>
          Welcome<span className="invisible"> To CheckMySkill</span>!
        </h2>
      </div>
      <div className="heading">
        <h3>Introduction</h3>
        <div className="semi-intro">
          Welcome to the test summary and instructions!
        </div>
      </div>
      {sections && (sections?.length > 0) && (<Divider />)}
      {sections && (sections?.length > 0) && (
      <div className="test-summary">
        <div className="heading">
          <h3>Test Summary</h3>
        </div>
        <div className="summary-list">
          <div className="summary-header">
            <div className="column exam-part">Exam Part</div>
            <div className="column center">Suggested Time(mins)</div>
          </div>
          {sections &&
            sections.map((item, index) => (
              <div key={index} className="summary-item">
                <div className="column exam-part">{item?.sectionName}</div>
                <div className="column suggested-time center">
                  {(item?.totalTime)/60  || "-"}
                </div>
              </div>
            ))}
          <div className="total-time">
            <span className="time-limit">Time Limit</span>{" "}
            <span className="time-limit">{`: ${displayTime}`}</span>
          </div>
        </div>
      </div>)}
    </div>
  );
};

export default TestIntroductionCard;
