import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_question.scss";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Roles, TabStatus } from "../../constants/enums/enums";
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import {
  removeQuestion,
  setIsAddQuestion,
  setIsViewing,
  setSelectedQuestion,
  toggleCollapsed,
  toggleIsModalOpen,
  setIsEditQuestion,
  setTags,
  setPagination
} from "../../redux/slice/question";
import { debounce } from "lodash";
import {
  addQuestionService,
  deleteQuestionService,
  editQuestionService,
  getAllQuestionService,
} from "../../services/question";
import {
  ADD_QUESTION_ADMIN,
  GET_UPDATE_DELETE_QUESTION_ADMIN,
  GET_ALL_QUESTIONS_ADMIN,
} from "../../constants/endpoints/question";
import { setQuestions } from "../../redux/slice/question";
import { Button, Input, Modal, Segmented, Table, Tooltip, message } from "antd";
import { QuestionInterface } from "../../constants/interfaces/question";

import {EyeTwoTone, DeleteFilled, EditOutlined} from "@ant-design/icons"
import QuestionModal from "../../components/Modals/QuestionModal/AddEditViewQuestionModal";
import { htmlToString } from "../../utils/helpers";
import { getAllTagService } from "../../services/tag";
import { GET_ALL_TAGS_ADMIN } from "../../constants/endpoints/tag";
import useQueryParams from "../../utils/useQueryParams";

const Question: React.FC = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState(TabStatus.ALL);
  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    questionData,
    isCollapsed,
    isViewing,
    selectedQuestion,
    isModalOpen,
    isAddQuestion,
    isEditQuestion,
    tags,
    paginationData
  } = useSelector((state: RootState) => state.questions);
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllQuestions = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;

        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');
  
        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };

        const response = await getAllQuestionService(
          GET_ALL_QUESTIONS_ADMIN,
          paramObj
        );
        if (response.success) {
          if (q === "" || !q) {
            dispatch(setQuestions(response?.data?.data));
              dispatch(setPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              }));
            } else {
              dispatch(setQuestions(response?.data));
              dispatch(setPagination({
                totalCount: response?.data?.length,
                currentPage: 1,
                pageSize: 50
              }))
            }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN)
    ) {
      debouncedGetAllQuestions();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };
    
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllQuestions]);

  const handleDelete = async (question: QuestionInterface) => {
    try {
      setIsLoader(true);
      const response = await deleteQuestionService(
        `${GET_UPDATE_DELETE_QUESTION_ADMIN.replace(":id", question._id)}`
      );

      if (response?.success) {
        dispatch(removeQuestion(question._id));
        message.success("Question removed successfully!");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.error("Failed to delete question", err);
    }
  };

  const confirmDelete = (question: QuestionInterface) => {
    Modal.confirm({
      title: "Are you sure you want to delete this question?",
      onOk: () => handleDelete(question),
      okText: "Yes",
      cancelText: "No",
      centered: true,
    });
  };

  const handleTabChange = (key: TabStatus) => {
    removeQueryParam("page");
    debouncedGetAllQuestions("", key, 1, 10, true);
    setSelectedTab(key);
  };

  const handleTableChange = (currentPage: number) => {
    setIsLoader(true)
    updateQueryParam("page", `${currentPage}`);
    debouncedGetAllQuestions(searchTerm, TabStatus.ALL, currentPage, paginationData?.pageSize, true);
  };  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedGetAllQuestions(value);
  };

  const handleAddQuestion = () => {
    fetchAllTags();
    dispatch(setSelectedQuestion(null));
    dispatch(setIsAddQuestion(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleView = (question: QuestionInterface) => {
    dispatch(setSelectedQuestion(question));
    dispatch(setIsViewing(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleModalToggle = () => {
    if (isViewing) dispatch(setIsViewing(false));
    if (isAddQuestion) dispatch(setIsAddQuestion(false));
    if (isEditQuestion) dispatch(setIsEditQuestion(false));
    dispatch(toggleIsModalOpen(false));
  };
  const handleEdit = (question: QuestionInterface) => {
    fetchAllTags();
    dispatch(setSelectedQuestion(question));
    dispatch(setIsEditQuestion(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleSubmit = async (values: QuestionInterface) => {
    try {
      setIsLoader(true);
      const response = isEditQuestion ? await editQuestionService(GET_UPDATE_DELETE_QUESTION_ADMIN.replace(':id', values._id), values) : await addQuestionService(ADD_QUESTION_ADMIN, values);
      if (response.success) {
        message.success("Question added successfully!");
        debouncedGetAllQuestions();
        handleModalToggle();
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      handleModalToggle();
    }
  };

  const fetchAllTags = useCallback(async () => {
    try {
      const paramObj = {
        isDeleted: false
      }
      const response = await getAllTagService(
        GET_ALL_TAGS_ADMIN, paramObj
      );
      if (response?.success) {
        dispatch(setTags(response?.data));
      }
    } catch (err) {
    }
  }, [dispatch]);

  const columns = [
    {
      title: "S.No.",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Question",
      key: "question",
      render: (questionData: QuestionInterface) => 
         <Tooltip title={htmlToString(questionData.quesText)}>
          <div className="ellipsis custom-width">{htmlToString(questionData.quesText)}
          </div>
        </Tooltip> 
    },
    {
      title: "Type",
      key: "type",
      render: (questionData: QuestionInterface) =>
        questionData.quesType || "-",
    },
    {
      title: "Difficulty",
      key: "difficulty",
      render: (questionData: QuestionInterface) =>
        questionData.quesDifficulty || "-",
    },
    {
      title: "Actions",
      key: "actions",
      render: (questionData: QuestionInterface) => (
        <div className="action-section">
          <Button type="link" onClick={() => handleView(questionData)}>
          <EyeTwoTone style={{ fontSize: "18px" }} />
          </Button>
          {(selectedTab !== TabStatus.INACTIVE) && (<Button type="link" onClick={() => handleEdit(questionData)}>
            <EditOutlined style={{ fontSize: "18px" }} />
          </Button>)}
          <Button type="link" onClick={() => confirmDelete(questionData)}>
          <DeleteFilled style={{ fontSize: "18px", color: 'red' }} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      {user?.role === Roles.SUPER_ADMIN ? (
        <div className={`question ${isCollapsed ? "collapsed" : "open"}`}>
          <Header title={"Questions"} />
          <div className="head">
            <div>
              <Segmented
                defaultValue={TabStatus.ALL}
                onChange={handleTabChange}
                options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
              />
            </div>
            <div className="head-right">
              <Input
                className="antd-search-input-custom"
                placeholder="Search questions"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button
                type="primary"
                className="button"
                onClick={handleAddQuestion}
              >
                Add Question
              </Button>
            </div>
          </div>
          <div className="table">
            <Table
              dataSource={questionData || []}
              columns={columns}
              rowKey={(record) => record._id}
              loading={!questionData || isLoader}
              pagination={{
                current: paginationData?.currentPage,
                pageSize: paginationData?.pageSize,
                total: paginationData?.totalCount || 0, 
                onChange: handleTableChange,
              }}
              scroll={{ y: '61dvh' }}
            />
          </div>
        </div>
      ) : (
        <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>
      )}
      <QuestionModal
        isViewing={isViewing}
        isModalOpen={isModalOpen}
        isAddQuestion={isAddQuestion}
        isEditQuestion={isEditQuestion}
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        selectedQuestion={selectedQuestion}
        tags={tags} 
      />
    </div>
  );
};

export default Question;