import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slice/auth';
import { combineReducers } from 'redux';
import candidatesReducer from './slice/candidates';
import organizationsReducer from './slice/organizations';
import institutesReducer from './slice/institutes';
import tagsReducer from './slice/tags';
import questionsReducer from './slice/question';
import testsReducer from './slice/test';
import candidateTestReducer from './slice/candidateTest';
import usersReducer from './slice/user';

// Config for persisting only the auth reducer
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only persist auth reducer
};

const rootReducer = combineReducers({
  auth: authReducer,
  candidates: candidatesReducer,
  organizations: organizationsReducer,
  institutes: institutesReducer,
  tags: tagsReducer,
  questions: questionsReducer,
  tests: testsReducer,
  candidateTests: candidateTestReducer,
  users: usersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;