import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./_questionForm.scss";
import { QuestionFormProps } from "../../../constants/interfaces/question";
import { TagInterface } from "../../../constants/interfaces/tag";

const { Option } = Select;

const QuestionForm: React.FC<QuestionFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  isEditQuestion,
  tags,
}) => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState<string[]>(["", "", "", ""]);
  const [quesType, setQuesType] = useState<string>("MCQ");
  const [allOptionsFilled, setAllOptionsFilled] = useState<boolean>(false);

  const quillRef = useRef(null);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        id: initialValues?._id,
        quesText: initialValues?.quesText,
        answer: initialValues?.answer,
        quesType: initialValues?.quesType || "MCQ",
        quesDifficulty: initialValues?.quesDifficulty || "Low",
        quesLang: initialValues?.quesLang || "English",
        quesTags: initialValues?.quesTags?.map((tag: TagInterface) => tag._id) || [], 
        option1: initialValues?.quesOptions?.[0] || '',
        option2: initialValues?.quesOptions?.[1] || '',
        option3: initialValues?.quesOptions?.[2] || '',
        option4: initialValues?.quesOptions?.[3] || '',
      });
      setOptions(initialValues.quesOptions || ["", "", "", ""]);
    }

  }, [initialValues, form]);
  
  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
    form.setFieldsValue({ [`option${index + 1}`]: value });
    if (options.every(option => option !== '')) setAllOptionsFilled(true);
  };

  const handleAnswerChange = (value: string | string[]) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    form.setFieldsValue({ answer: selectedValues });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      delete values.option1;
      delete values.option2;
      delete values.option3;
      delete values.option4;
      let combinedValues = {
        ...values,
        quesOptions: options,
        ...(initialValues._id && { _id: initialValues._id})
      };
      onSubmit(combinedValues);
      form.resetFields();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Form form={form} layout="vertical" className="question-form">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Question Type"
            name="quesType"
            rules={[{ required: true, message: "Please select the question type!" }]}
          >
            <Select
              disabled={isEditQuestion}
              onChange={(value) => setQuesType(value)}>
              <Option value="MCQ">MCQ (Single correct answer)</Option>
              <Option value="MSQ">MSQ (Multiple correct answers)</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Question Difficulty"
            name="quesDifficulty"
            rules={[{ required: true, message: "Please select the difficulty level!" }]}
          >
            <Select  disabled={isEditQuestion}>
              <Option value="Low">Low</Option>
              <Option value="Medium">Medium</Option>
              <Option value="Hard">Hard</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Question Language"
            name="quesLang"
            rules={[{ required: true, message: "Please select the question language!" }]}
          >
            <Select disabled>
              <Option value="English">English</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Tag"
            name="quesTags"
            rules={[{ required: true, message: "Please select a tag!" }]}
          >
            <Select placeholder="Select tag" mode="multiple">
              {tags?.map((tag) => (
                <Option key={tag._id} value={tag._id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Question"
        name="quesText"
        rules={[{ required: true, message: "Please input the question!" }]}
      >
        <ReactQuill ref={quillRef} placeholder="Enter question" readOnly={isEditQuestion}/>
      </Form.Item>

      <Form.Item label="Options" required>
        <Row gutter={16}>
           <Col span={12}>
              <Form.Item
                name={`option1`}
                rules={[{ required: true, message: `Please input option 1!` }]}
                noStyle
              >
                <Input
                  disabled={isEditQuestion}
                  placeholder={`Option 1`}
                  onChange={(e) => handleOptionChange(0,e.target.value)}
                  style={{ margin: '4px 0' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={`option2`}
                rules={[{ required: true, message: `Please input option 2!` }]}
                noStyle
              >
                <Input
                  disabled={isEditQuestion}
                  placeholder={`Option 2`}
                  onChange={(e) => handleOptionChange(1,e.target.value)}
                  style={{ margin: '4px 0' }}
                />
              </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
           <Col span={12}>
              <Form.Item
                name={`option3`}
                rules={[{ required: true, message: `Please input option 3!` }]}
                noStyle
              >
                <Input
                  disabled={isEditQuestion}
                  placeholder={`Option 3`}
                  onChange={(e) => handleOptionChange(2,e.target.value)}
                  style={{ margin: '4px 0' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={`option4`}
                rules={[{ required: true, message: `Please input option 4!` }]}
                noStyle
              >
                <Input
                  disabled={isEditQuestion}
                  placeholder={`Option 4`}
                  onChange={(e) => handleOptionChange(3,e.target.value)}
                  style={{ margin: '4px 0' }}
                />
              </Form.Item>
            </Col>
        </Row>
      </Form.Item>

      <Form.Item
        label="Answer"
        name="answer"
        rules={[{ required: true, message: "Please select the correct answer!" }]}
      >
        <Select
          mode={quesType === "MSQ" ? "multiple" : undefined}
          placeholder="Select the correct answer(s)"
          disabled={!allOptionsFilled || isEditQuestion}
          onChange={handleAnswerChange}
        >
          {options.map((option, index) => (
            <Option key={index} value={option}>
              {option || `Option ${index + 1}`}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <div className="form-actions">
        <Button className="cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" className="submit-button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default QuestionForm;