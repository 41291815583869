import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Upload, Radio } from "antd";
import "./_candidateForm.scss";
import { OrgType } from "../../../constants/enums/enums";
import { CandidateFormProps } from "../../../constants/interfaces/candidate";

const { Option } = Select;

const CandidateForm: React.FC<CandidateFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  organizations,
  institutes,
  isBulkUploading,
  uploadProps,
}) => {
  const [form] = Form.useForm();
  const [selected, setSelected] = useState(OrgType.ORGANIZATION);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
      form.resetFields();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Form form={form} layout="vertical" className="candidate-form">
      {!isBulkUploading && (
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input the first name!" }]}
        >
          <Input placeholder="Enter first name" />
        </Form.Item>
      )}

      {!isBulkUploading && (
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Please input the last name!" }]}
        >
          <Input placeholder="Enter last name" />
        </Form.Item>
      )}

      {!isBulkUploading && (
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input the email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
      )}

      {!isBulkUploading && (
        <Form.Item
          label="Phone"
          name="phone"
          rules={[
            { required: true, message: "Please input the phone number!" },
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number!",
            },
          ]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>
      )}

      {isBulkUploading && (
        <>
          <Form.Item
            label="Upload CSV"
            name="file"
            rules={[{ required: true, message: "Please upload the file" }]}
          >
            <Upload {...uploadProps}>
              <Button>Select CSV File</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="link" href="./candidates.csv" download="sample.csv" style={{padding: '0px'}}>
              Download Sample CSV
            </Button>
          </Form.Item>
        </>
      )}

      <div>
        <Radio.Group
          value={selected}
          onChange={(e) => setSelected(e.target.value as OrgType)}
        >
          <Radio value={OrgType.ORGANIZATION} className="radio-text">
            Organization
          </Radio>
          <Radio value={OrgType.INSTITUTE} className="radio-text">
            Institute
          </Radio>
        </Radio.Group>
      </div>

      {selected === OrgType.ORGANIZATION && (
        <Form.Item
          label="Organization"
          name="organization"
          rules={[
            { required: true, message: "Please select an organization!" },
          ]}
        >
          <Select placeholder="Select organization">
            {organizations?.map((org) => (
              <Option key={org._id} value={org._id}>
                {org.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {selected === OrgType.INSTITUTE && (
        <Form.Item
          label="Institute"
          name="institute"
          rules={[{ required: true, message: "Please select an institute!" }]}
        >
          <Select placeholder="Select institute">
            {institutes?.map((institute) => (
              <Option key={institute._id} value={institute._id}>
                {institute.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <div className="form-actions">
        <Button className="cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" className="submit-button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default CandidateForm;
