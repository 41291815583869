import { Modal } from "antd";
import React from "react";
import TestIntroductionCard from "../../../Cards/Introduction/TestIntroductionCard";
import TestInstructionCard from "../../../Cards/Instruction/TestInstructionCard";
import { TestInstructionModalProps } from "../../../../constants/interfaces/test";
import './_testInstructionModal.scss';

const TestInstructionModal: React.FC<TestInstructionModalProps> = ({
  testData,
  openModal,
  onCancel,
}) => {
  return (
    <Modal
    open={openModal}
    onCancel={onCancel}
    footer={null}
    className="modal-width-height"
    >
      <div className="instruction-Modal">
        <div>
          <TestIntroductionCard sections={testData?.sections} />
        </div>
        <div>
          <TestInstructionCard sections={testData?.sections} />
        </div>
      </div>
    </Modal>
  );
};

export default TestInstructionModal;
