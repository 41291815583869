import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_candidate.scss";
import Header from "../../components/Header/Header";
import { Button, Input, Modal, Segmented, Table, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import { EyeTwoTone, DeleteFilled } from "@ant-design/icons";
import {
  setCandidates,
  toggleCollapsed,
  toggleIsModalOpen,
  setSelectedCandidate,
  setIsViewing,
  removeCandidate,
  setOrganizations,
  setBulkUploading,
  setIsAddCandidate,
  setInstitutes,
  setPagination,
} from "../../redux/slice/candidates";
import { debounce } from "lodash";
import CandidateModal from "../../components/Modals/CandidateModal/AddViewCandidateModal";
import {
  ADD_CANDIDATE,
  BULK_ADD_CANDIDATE,
  DELETE_CANDIDATE_ADMIN,
  GET_ALL_CANDIDATES_ADMIN,
} from "../../constants/endpoints/candidate";
import { GET_ALL_ORGANIZATIONS_ADMIN } from "../../constants/endpoints/organization";
import { Roles, TabStatus } from "../../constants/enums/enums";
import {
  addCandidateService,
  bulkAddCandidateService,
  deleteCandidateService,
  getAllCandidateService,
} from "../../services/candidate";
import { CandidateInterface } from "../../constants/interfaces/candidate";
import { getAllOrganizaionService } from "../../services/organization";
import { getAllInstituteService } from "../../services/institute";
import { GET_ALL_INSTITUTE_ADMIN } from "../../constants/endpoints/institute";
import useQueryParams from "../../utils/useQueryParams";

const Candidate: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoader, setIsLoader] = useState(false);
  const {
    candidateData,
    isCollapsed,
    isModalOpen,
    selectedCandidate,
    isViewing,
    organizations,
    isBulkUploading,
    isAddCandidate,
    institutes,
    paginationData
  } = useSelector((state: RootState) => state.candidates);
  const apiCalled = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllCandidates = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;

        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');

        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };

        const response = await getAllCandidateService(
          GET_ALL_CANDIDATES_ADMIN,
          paramObj
        );
        if (response.success) {
          if (q === "" || !q) {
            dispatch(setCandidates(response?.data?.data));
              dispatch(setPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              }));
            } else {
              dispatch(setCandidates(response?.data));
              dispatch(setPagination({
                totalCount: response?.data?.length,
                currentPage: 1,
                pageSize: 50
              }))
            }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 500),
    [dispatch]
  );

  const fetchAllOrganizations = useCallback(async () => {
    try {
      const paramObj = {
        isDeleted: false,
      };
      const response = await getAllOrganizaionService(
        GET_ALL_ORGANIZATIONS_ADMIN,
        paramObj
      );
      if (response?.success) {
        dispatch(setOrganizations(response?.data));
      }
    } catch (err) {
      console.error("Failed to fetch organizations", err);
    }
  }, [dispatch]);

  const fetchAllInstitutes = useCallback(async () => {
    try {
      const paramObj = {
        isDeleted: false,
      };
      const response = await getAllInstituteService(
        GET_ALL_INSTITUTE_ADMIN,
        paramObj
      );
      if (response?.success) {
        dispatch(setInstitutes(response?.data));
      }
    } catch (err) {
      console.error("Failed to fetch organizations", err);
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN)
    ) {
      debouncedGetAllCandidates();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllCandidates]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedGetAllCandidates(value);
  };

  const handleTabChange = (key: string) => {
    removeQueryParam("page");
    debouncedGetAllCandidates("", key, 1, 10, true);
  };

  const handleTableChange = (currentPage: number) => {
    setIsLoader(true)
    updateQueryParam("page", `${currentPage}`);
    debouncedGetAllCandidates(searchTerm, TabStatus.ALL, currentPage, paginationData?.pageSize, true);
  };  

  const handleModalToggle = () => {
    isViewing && dispatch(setIsViewing(false));
    isBulkUploading && dispatch(setBulkUploading(false));
    isAddCandidate && dispatch(setIsAddCandidate(false));
    dispatch(toggleIsModalOpen(false));
  };

  const handleSubmit = async (
    values: CandidateInterface,
    isBulkUploading = false
  ) => {
    try {
      setIsLoader(true);
      let response;
      if (values?.file && isBulkUploading) {
        response = await bulkAddCandidateService(BULK_ADD_CANDIDATE, values);
      } else {
        response = await addCandidateService(ADD_CANDIDATE, values);
      }
      if (response.success) {
        message.success("Candidate added successfully!");
        debouncedGetAllCandidates();
        handleModalToggle();
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
    }
  };

  const handleView = (candidate: CandidateInterface) => {
    dispatch(setSelectedCandidate(candidate));
    dispatch(setIsViewing(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleBulkUpload = () => {
    fetchAllInstitutes();
    fetchAllOrganizations();
    dispatch(setBulkUploading(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleAddCandidate = () => {
    fetchAllInstitutes();
    fetchAllOrganizations();
    dispatch(setSelectedCandidate(null));
    dispatch(setIsAddCandidate(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleDelete = async (candidate: CandidateInterface) => {
    try {
      setIsLoader(true);
      const response = await deleteCandidateService(
        `${DELETE_CANDIDATE_ADMIN.replace(":id", candidate._id)}`
      );

      if (response?.success) {
        dispatch(removeCandidate(candidate._id));
        message.success("Candidate removed successfully!");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.error("Failed to delete candidate", err);
    }
  };

  const confirmDelete = (candidate: CandidateInterface) => {
    Modal.confirm({
      title: "Are you sure you want to delete this candidate?",
      onOk: () => handleDelete(candidate),
      okText: "Yes",
      cancelText: "No",
      centered: true,
    });
  };

  const columns = [
    {
      title: "S.No.",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      key: "name",
      render: (candidate: CandidateInterface) =>
        `${candidate.firstName} ${candidate.lastName}`,
    },
    {
      title: "Email",
      key: "email",
      render: (candidate: CandidateInterface) => candidate.email,
    },
    {
      title: "Institute",
      key: "institute",
      render: (candidate: CandidateInterface) =>
        `${candidate?.institute?.name || "-"}`,
    },
    {
      title: "Organization",
      key: "organization",
      render: (candidate: CandidateInterface) =>
        `${candidate?.organization?.name || "-"}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (candidate: CandidateInterface) => (
        <div className="action-section">
          <Button type="link" onClick={() => handleView(candidate)}>
            <EyeTwoTone style={{ fontSize: "18px" }} />
          </Button>
          <Button type="link" onClick={() => confirmDelete(candidate)}>
            <DeleteFilled style={{ fontSize: "18px", color: "red" }} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      {user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN || user?.role === Roles.SUPER_ADMIN ? (
        <div className={`user ${isCollapsed ? "collapsed" : "open"}`}>
          <Header title={"Candidates"} />
          <div className="head">
            <div>
              <Segmented
                defaultValue={TabStatus.ALL}
                onChange={handleTabChange}
                options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
              />
            </div>
            <div className="head-right">
              <Input
                className="antd-search-input-custom"
                placeholder="Search candidates"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button
                type="primary"
                className="button"
                onClick={handleBulkUpload}
              >
                Bulk Add Candidate
              </Button>
              <Button
                type="primary"
                className="button"
                onClick={handleAddCandidate}
              >
                Add Candidate
              </Button>
            </div>
          </div>
          <div className="table">
            <Table
              dataSource={candidateData || []}
              columns={columns}
              rowKey={(record) => record._id}
              loading={!candidateData || isLoader}
              pagination={{
                current: paginationData?.currentPage,
                pageSize: paginationData?.pageSize,
                total: paginationData?.totalCount || 0, 
                onChange: handleTableChange,
              }}
              scroll={{ y: '61dvh' }}
            />
          </div>
        </div>
      ) : (
        <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>
      )}

      <CandidateModal
        isViewing={isViewing}
        isModalOpen={isModalOpen}
        isBulkUploading={isBulkUploading}
        isAddCandidate={isAddCandidate}
        selectedCandidate={selectedCandidate}
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        organizations={organizations}
        institutes={institutes}
      />
    </div>
  );
};

export default Candidate;