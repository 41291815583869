import React, { useCallback, useEffect, useRef, useState } from "react";
import TestInstruction from "../test-intruction/testInstruction";
import './_fullScreenTest.scss';
import TestSession from "../test-session/testSession";
import { getSectionQuestionService, getSectionService, saveTestAnswerService, startTestSessionService, submitTestSessionService } from "../../services/testSession";
import { GET_QUESTION_CANDIDATE, GET_TEST_SECTION_CANDIDATE, SAVE_ANSWER_CANDIDATE, START_TEST_SESSION_CANDIDATE, SUBMIT_TEST_SESSION_CANDIDATE } from "../../constants/endpoints/testSession";
import { Modal, message } from "antd";
import { useNavigate } from "react-router-dom";

const FullScreenApp: React.FC = () => {
  const [isInactive, setIsInactive] = useState(false);
  const [inActiveWindowTimer, setInActiveWindowTimer] = useState(5);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [currentSection, setCurrentSection] = useState('');
  const [isTimerActive, setIsTimerActive] = useState(false); 
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const testData = JSON.parse(localStorage.getItem('testData') as string);

  const navigate = useNavigate();

  const openFullScreen = () => {
    const elem = window.document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if ((elem as any).webkitRequestFullscreen) {
      (elem as any).webkitRequestFullscreen();
    } else if ((elem as any).msRequestFullscreen) {
      (elem as any).msRequestFullscreen();
    }
  };
  
  const startInactivityTimer = useCallback(() => {
    setIsTimerActive(true);
    intervalRef.current = setInterval(() => {
      setInActiveWindowTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current!);
          intervalRef.current = null;
          Modal.destroyAll();
          navigate("/c/tests");
        }
        return prevTime - 1;
      });
    }, 1000);
  }, [navigate]);

  const resetInactivityTimer = useCallback(() => {
    setIsInactive(false);
    setInActiveWindowTimer(5);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; 
    }
    setIsTimerActive(false);
  }, []);

  const handleUserActivity = useCallback(() => {
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  const handleBlur = useCallback(() => {
    setIsInactive(true);
    startInactivityTimer();
  }, [startInactivityTimer]);

  useEffect(() => {
    if (isInactive) {
      Modal.info({
        title: "Test will close soon!",
        content: (
          <div>
            <p>Test will close within 5 seconds! Otherwise click continue</p>
          </div>
        ),
        onOk: () => {
          resetInactivityTimer()
          openFullScreen();
        },
        okText: "Continue",
        closable: false,
        width: 300,
        centered: true,
      });
    }
  }, [isInactive, resetInactivityTimer]);

  const handleFullScreenChange = useCallback(() => {
    if (!document.fullscreenElement) {
      handleBlur();
    } 
  }, [handleBlur]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      e.preventDefault();
    };

    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };

    const handleMouseClick = (e: MouseEvent) => {
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("contextmenu", handleRightClick);
    window.addEventListener("mousedown", handleMouseClick);
    window.addEventListener("mouseup", handleMouseClick);
    window.addEventListener("click", handleMouseClick);
    window.addEventListener("focus", handleUserActivity); 
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("contextmenu", handleRightClick);
      window.removeEventListener("mousedown", handleMouseClick);
      window.removeEventListener("mouseup", handleMouseClick);
      window.removeEventListener("click", handleMouseClick);
      window.removeEventListener("focus", handleUserActivity);
      window.removeEventListener("blur", handleBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveWindowTimer, isTimerActive ]);

  const handleQuestionChange = async (quesId: string, answer: string[]) => {
    if (questionList?.length === 1) {
      await submitAnswer(quesId, sectionList[0], answer);
      if (sectionList.length === 1) {
        await submitTestSessionService(SUBMIT_TEST_SESSION_CANDIDATE, {sessionId});
        navigate('/c/completed')
      } else {
        await getTestSection(sectionList[1], sessionId)
        setSectionList((prevList) => prevList.slice(1));
      }
    } else {
      await submitAnswer(quesId, sectionList[0], answer); 
    }
  }

  const submitAnswer = async (quesId: string, sectionId: string, answer: string[]) => {
    try {
        setIsLoader(true);
        const response = await saveTestAnswerService(SAVE_ANSWER_CANDIDATE, { quesId, sessionId, sectionId, answer });
        if (response.success && questionList.length > 1) {
          await getTestQuestion(sessionId, sectionId, questionList[1])
          setQuestionList((prevList) => prevList.slice(1));
          message.success('Answer submitted!');
        }
        setIsLoader(false);
    } catch (e) {
        setIsLoader(false);
    }
  }

  const getTestQuestion = async (sessionId: string, sectionId: string, quesId: string) => {
    try {
      const response = await getSectionQuestionService(GET_QUESTION_CANDIDATE, {sectionId,sessionId, quesId});
      if(response.success) {
        setQuestionData({...response?.data, _id: quesId});
      }
    } catch (e) {}
  }

  const getTestSection = async (sessionId: string, sectionId: string) => {
    try {
      const response = await getSectionService(GET_TEST_SECTION_CANDIDATE, {sessionId, sectionId});
        if (response?.success) {
          setQuestionList(response?.data?.questions);
          setCurrentSection(response?.data?.sectionName);

          await getTestQuestion(sessionId, sectionId, response?.data?.questions[0]);

        }
    } catch (e) {}
  }

  const startTest = async () => {
    try {
      setIsLoader(true);
      const response = await startTestSessionService(START_TEST_SESSION_CANDIDATE, {testId: testData?._id ? testData?._id : ''});
      if (response.success) {
        setIsTestStarted(true);
        setSessionId(response?.data?._id);
        setSectionList(response?.data?.sections);

        await getTestSection(response?.data?._id, response?.data?.sections[0])

        setIsLoader(false);
      }
    } catch (e) {setIsLoader(false)}
  }

  return (
    <div className="full-screen">
      {!isTestStarted && <TestInstruction testData={testData} startTest={startTest} openFullScreen={openFullScreen}/>}
      {isTestStarted && <TestSession handleQuestionChange={handleQuestionChange} questionData={questionData} sectionName={currentSection} isLoader={isLoader}/>}
    </div>
  )
};

export default FullScreenApp;
