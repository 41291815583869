import moment from "moment";
import { Ports } from "../config/config";
import { RangePickerProps } from "antd/es/date-picker";
import Bowser from 'bowser';

declare global {
  interface Navigator {
    brave?: {
      isBrave: () => Promise<boolean>;
    };
  }
}


export const getBaseURL = (service: keyof typeof Ports): string => {
  const isLocal = process.env.REACT_APP_ENVIRONMENT === "local";
  const port = Ports[service];

  return isLocal
    ? `http://localhost:${port}`
    : `${process.env.REACT_APP_BACKEND_BASE_URL}`;
};

export const makeQueryParam = (param: any) => {
  if (!param) return "";
  let query =
    "?" +
    Object.keys(param)
      .map((data) => {
        return data + "=" + encodeURIComponent(param[data]);
      })
      .join("&");

  return query;
};

export const htmlToString = (html: string | undefined) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html as string, "text/html");
  return doc.body.textContent || "";
};

export const getIndianStates = () => {
  return [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir",
  ];
};

export const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < moment().startOf("day");
};

export const disabledDateTime = () => {
  // const currentMoment = moment();
  // const currentHour = currentMoment.hour();
  // const currentMinute = currentMoment.minute();
  // const minSelectableMinute = currentMinute + 20;

  return {
    // disabledHours: () => {
    //   return Array.from({ length: 24 }, (_, i) => i).splice(0, currentHour);
    // },
    // disabledMinutes: (selectedHour: number) => {
    //   if (selectedHour === currentHour) {
    //     return Array.from({ length: 60 }, (_, i) => i).splice(0, minSelectableMinute);
    //   }
    //   return [];
    // },
    // disabledSeconds: (selectedHour: number, selectedMinute: number) => {
    //   if (selectedHour === currentHour && selectedMinute === minSelectableMinute) {
    //     return Array.from({ length: 60 }, (_, i) => i).splice(0, 30);
    //   }
    //   return [];
    // },
  };
};

export const formatDateInHrMinSec = (date: Date) => {
  return moment(date).format("DD MMM, YYYY hh:mm A");
};

export const getAllDeviceDetails = async () => {
  try {
    const browser = Bowser?.getParser(window.navigator.userAgent);
    if (browser) {
      let browserInfo = browser.getBrowser();
      const osInfo = browser.getOS();
      const platformInfo = browser.getPlatform();

      if (await isBrave()) {
        browserInfo.name = 'Brave';
      }
      return {
        browserInfo,
        osInfo,
        platformInfo,
      }
    }
  } catch (e) {
    return {
      browserInfo: {name: 'Unknown', version: 'Unknown'},
      osInfo: {name: 'Unknown', version: 'Unknown', versionName: 'Unknown'},
      platformInfo: {type: 'Unknown', vendor: 'Unknown'},
    }
  }
}

export const isBrave = async () => {
  if (navigator.brave && typeof navigator.brave.isBrave === "function") {
    return await navigator.brave.isBrave();
  }
  return false;
};

export const detectIncognitoMode = async () => {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    const { quota } = await navigator.storage.estimate();

    if (quota && quota < 147064317542) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};