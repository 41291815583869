export enum AccountType {
    ADMIN = 'admin',
    CANDIDATE = 'candidate'
}

export enum Roles {
    SUPER_ADMIN = 'Super Admin',
    ORG_ADMIN = 'Organization Admin',
    INS_ADMIN = 'Institute Admin',
    CANDIDATE = 'Candidate'
}

export enum TabStatus {
    ACTIVE= 'Active',
    INACTIVE = 'Inactive',
    ALL = 'All',
}

export enum OrgType  {
    ORGANIZATION = 'organization',
    INSTITUTE = 'institute'
}

export enum RedirectRoutes {
    CANDIDATE = '/c/tests',
    ADMIN = '/dashboard' 
}

export enum TestStatus {
    PENDING = 'Pending',
    COMPLETED = 'Completed'
}

export enum TestStage {
    FIRST = 'first',
    SECOND = 'second'
}

export enum TestType {
    PROFICIENCY = 'Proficiency Test',
    ASSESSMENT = 'Assessment',
    COMPETITION='Competition',
    MOCK = 'Mock Test'
}

export enum QuestionType {
    MCQ ='mcq',
    MSQ = 'msq',
} 