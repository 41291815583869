import React, { useEffect } from "react";
import { UserProfileFormProps } from "../../../constants/interfaces/user";
import { Button, Col, Form, Input, Row, Select } from "antd";
import './_profileForm.scss';
import { getIndianStates } from "../../../utils/helpers";

const ProfileForm: React.FC<UserProfileFormProps> = ({ 
  onSubmit,
  organizations,
  institutes,
  user,
  tags,
 }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  useEffect(() => {
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      institute: user?.institute?.name,
      organization: user?.organization?.name,
      skills: user?.skills?.map((tag: any) => tag._id) || [],
      permanentAddress: user?.permanentAddress,
      currentAddress: user?.currentAddress
    })
  })

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      delete values?.institute;
      onSubmit(values);
      form.resetFields();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Form form={form} layout="vertical" className="profile-form">
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input the email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input placeholder="Enter email" disabled/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Please input the phone number!" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number!",
              },
            ]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          {user?.organization?.name && (
            <Form.Item
              label="Organization"
              name="organization"
              rules={[
                { required: true, message: "Please select an organization!" },
              ]}
            >
              <Select placeholder="Select organization" disabled={true}>
                {organizations?.map((org) => (
                  <Option key={org._id} value={org._id}>
                    {org.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {user?.institute?.name && (
            <Form.Item
              label="Institute"
              name="institute"
              rules={[{ required: true, message: "Please select an institute!" }]}
            >
              <Select placeholder="Select institute" disabled={true}>
                {institutes?.map((institute) => (
                  <Option key={institute._id} value={institute._id}>
                    {institute.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Skills"
            name="skills"
            rules={[{ required: true, message: "Please select a skill!" }]}
          >
            <Select placeholder="Select skills" mode="multiple">
              {tags?.map((tag) => (
                <Option key={tag._id} value={tag._id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <h3 className="address-heading">Permanent Address</h3>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Address"
            name={['permanentAddress', 'address']}
            rules={[{ required: true, message: "Please input the address!" }]}
          >
            <Input placeholder="Enter permanent address" maxLength={50}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Landmark"
            name={['permanentAddress', 'landmark']}
            rules={[{required: true, message: "Please input the landmark!"}]}
            className="white-label"
          >
            <Input placeholder="Enter landmark" maxLength={50}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="City"
            name={['permanentAddress', 'city']}
            rules={[{ required: true, message: "Please input the city name!" }]}
          >
            <Input placeholder="Enter city" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}> 
        <Col xs={24} sm={12} md={8} lg={8}>
        <Form.Item
            label="State"
            name={['permanentAddress', 'state']}
            rules={[{ required: true, message: "Please input the state!" }]}
          >
            <Select placeholder="Select state">
              {getIndianStates().map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
        </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Pincode"
            name={['permanentAddress', 'pincode']}
            rules={[{ required: true, message: "Please input the pincode!" }]}
          >
            <Input placeholder="Enter pincode" />
          </Form.Item>
        </Col>
      </Row>

      <h3 className="address-heading">Current Address</h3>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Address"
            name={['currentAddress', 'address']}
            rules={[{ required: true, message: "Please input the address!" }]}
          >
            <Input placeholder="Enter current address" maxLength={50}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Landmark"
            name={['currentAddress', 'landmark']}
            rules={[{required: true, message: "Please input the landmark!"}]}
          >
            <Input placeholder="Enter landmark" maxLength={50}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="City"
            name={['currentAddress', 'city']}
            rules={[{ required: true, message: "Please input the city name!" }]}
          >
            <Input placeholder="Enter city" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>    
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="State"
            name={['currentAddress', 'state']}
            rules={[{ required: true, message: "Please input the state name!" }]}
          >
             <Select placeholder="Select state">
              {getIndianStates().map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Form.Item
            label="Pincode"
            name={['currentAddress', 'pincode']}
            rules={[{ required: true, message: "Please input the pincode!" }]}
          >
            <Input placeholder="Enter pincode" />
          </Form.Item>
        </Col>
      </Row>

      <div className="form-actions">
        <Button type="primary" className="submit-button" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ProfileForm;