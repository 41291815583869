import React from "react";
import { Modal } from "antd";
import { TestModalProps } from "../../../constants/interfaces/test";
import "./_testModal.scss";
import TestForm from "../../Forms/TestForm/testForm";

const TestModal: React.FC<TestModalProps> = ({
  isViewing,
  isModalOpen,
  isAddTest,
  isEditTest,
  selectedTest,
  questionData,
  candidates,
  inputValue,
  selectedIds,
  sectionData,
  organizationData,
  instituteData,
  currentStage,
  setCurrentStage,
  handleModalToggle,
  handleSubmit,
  handleSectionAdd,
  handleSectionChange,
  handleDeleteSection,
  addDataToSection,
  handleCheckboxChange,
  setSelectedSection,
  handleIndividualQuestion,
  debouncedgetAllQuestion,
}) => {

  return (
    <Modal
      title={
        isViewing ? `Test Details` : isAddTest ? "Add New Test" : `Edit Test`
      }
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
      width={"100%"}
    >
            <TestForm
              initialValues={isAddTest ? {} : selectedTest}
              candidates={candidates}
              inputValue={inputValue}
              questionData={questionData}
              selectedIds={selectedIds}
              sectionData={sectionData}
              organizationData={organizationData}
              instituteData={instituteData}
              isEditTest={isEditTest}
              isViewing={isViewing}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              onSubmit={handleSubmit}
              onCancel={handleModalToggle}
              handleSectionAdd={handleSectionAdd}
              handleSectionChange={handleSectionChange}
              handleDeleteSection={handleDeleteSection}
              addDataToSection={addDataToSection}
              handleCheckboxChange={handleCheckboxChange}
              setSelectedSection={setSelectedSection}
              handleIndividualQuestion={handleIndividualQuestion}
              debouncedgetAllQuestion={debouncedgetAllQuestion}
            />
    </Modal>
  );
};

export default TestModal;
