import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationInterface } from '../../constants/interfaces/organization';
import { InstituteInterface } from '../../constants/interfaces/institute';
import { UserInterface, UserProfileStateInterface } from '../../constants/interfaces/user';
import { TagInterface } from '../../constants/interfaces/tag';

const initialState: UserProfileStateInterface = {
  userData: null,
  isCollapsed: false,
  organizations: [],
  institutes: [],
  tags: []
};

const candidatesSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserInterface>) => {
      state.userData = action.payload;
    },
    toggleCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<OrganizationInterface[]>) => {
      state.organizations = action.payload;
    },
    setInstitutes: (state, action: PayloadAction<InstituteInterface[]>) => {
      state.institutes = action.payload;
    },
    setTags: (state, action: PayloadAction<TagInterface[]>) => {
      state.tags = action.payload;
    },
  },
});

export const { toggleCollapsed, setOrganizations, setInstitutes, setUser, setTags } = candidatesSlice.actions;

export default candidatesSlice.reducer;