import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { QuestionState } from "../../constants/interfaces/question";
import { TagInterface } from "../../constants/interfaces/tag";
import { PaginationInterface } from "../../constants/interfaces/common";

const initialState: QuestionState = {
    questionData: null,
    isCollapsed: false,
    isModalOpen: false,
    selectedQuestion: null,
    isViewing: false,
    isAddQuestion: false,
    isEditQuestion: false,
    tags: null,
    paginationData: undefined,
  };
  
  const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
      setQuestions: (state, action: PayloadAction<any[]>) => {
        state.questionData = action.payload;
      },
      toggleCollapsed: (state, action: PayloadAction<boolean>) => {
        state.isCollapsed = action.payload;
      },
      toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
        state.isModalOpen =  action.payload;
      },
      setSelectedQuestion: (state, action: PayloadAction<any | null>) => {
        state.selectedQuestion = action.payload;
      },
      setIsViewing: (state, action: PayloadAction<boolean>) => {
        state.isViewing = action.payload;
      },
      removeQuestion: (state, action: PayloadAction<string>) => {
        state.questionData = state.questionData?.filter(
          (question) => question._id !== action.payload
        );
      },
      setIsAddQuestion: (state, action: PayloadAction<boolean>) => {
        state.isAddQuestion = action.payload;
      },
      setIsEditQuestion: (state, action: PayloadAction<boolean>) => {
        state.isEditQuestion = action.payload;
      },
      setTags: (state, action: PayloadAction<TagInterface[] | any | null>) => {
        state.tags = action.payload;
      },
      setPagination: (state, action: PayloadAction<PaginationInterface>) => {
        state.paginationData = action.payload;
      }
    },
  });
  
  export const { toggleCollapsed, toggleIsModalOpen, setSelectedQuestion, setIsViewing, removeQuestion, setIsAddQuestion, setQuestions, setIsEditQuestion, setTags, setPagination } = questionsSlice.actions;
  
  export default questionsSlice.reducer;