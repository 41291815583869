import React, { useState, useEffect } from 'react';
import InstructionHeader from '../../components/Header/Instruction/InstructionHeader';
import './_testSession.scss';
import { Radio, Checkbox, Button, Spin } from 'antd';
import { QuestionType } from '../../constants/enums/enums';
import { ClockCircleOutlined } from "@ant-design/icons";
import { htmlToString } from '../../utils/helpers';

interface TestSessionProps {
    handleQuestionChange: any;
    questionData: any;
    sectionName: string;
    isLoader: boolean;
}

const TestSession: React.FC<TestSessionProps> = ({ handleQuestionChange, questionData, sectionName, isLoader }) => {
    const questionType = questionData?.quesType;
    const options = questionData?.quesOptions || [];
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [timeRemaining, setTimeRemaining] = useState<number>(questionData?.timeRemaining);

    const handleMCQSelection = (e: any) => {
        setSelectedOptions([e.target.value]);
    };

    const handleMSQSelection = (option: string) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(option)
                ? prevSelected.filter((item) => item !== option)
                : [...prevSelected, option]
        );
    };

    useEffect(() => {
        if (timeRemaining && timeRemaining > 0) {
            const timerId = setInterval(() => {
                setTimeRemaining((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timerId);
        } else if (timeRemaining && timeRemaining < 1) {
            handleQuestionChange && handleQuestionChange(questionData?._id, selectedOptions);
        }
    }, [timeRemaining, handleQuestionChange, questionData, selectedOptions]);

    useEffect(() => {
        if (questionData?.timeRemaining !== undefined) {
            setTimeRemaining(questionData.timeRemaining);
        }
    }, [questionData]);


    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <div className='test-session-parent'>
            <InstructionHeader />
            {!isLoader ? (<><div className='section-header'> 
                <h2 className='section-title'>{sectionName}</h2> 
                <div className='timer'>
                    <ClockCircleOutlined />
                    <h3>{formatTime(timeRemaining)}</h3>
                </div>
            </div>
            <div className='question-content'>
                <h3 className='question-text'>{htmlToString(questionData?.quesText)}</h3>
                {questionType === QuestionType.MCQ ? (
                    <Radio.Group 
                        onChange={handleMCQSelection} 
                        value={selectedOptions[0]} 
                        className="option-group"
                    >
                        {options.map((option: string) => (
                            <Radio key={option} value={option} className="option">
                                {option}
                            </Radio>
                        ))}
                    </Radio.Group>
                ) : (
                    options.map((option: string) => (
                        <Checkbox
                            key={option}
                            onChange={() => handleMSQSelection(option)}
                            checked={selectedOptions.includes(option)}
                            className="option"
                        >
                            {option}
                        </Checkbox>
                    ))
                )}
            </div>
            <Button type="primary" className='next-button' onClick={() => {handleQuestionChange(questionData?._id, selectedOptions); setSelectedOptions([])}} disabled={selectedOptions.length === 0}>
                Next
            </Button></>) : <Spin className="center loader" />}
        </div>
    );
};

export default TestSession;