import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_report.scss";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Roles, TabStatus } from "../../constants/enums/enums";
import { debounce } from "lodash";
import { TestInterface } from "../../constants/interfaces/test";
import { Button, Input, Segmented, Table, Tooltip } from "antd";
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import {EyeTwoTone} from "@ant-design/icons"
import useQueryParams from "../../utils/useQueryParams";
import { getAllExecutedTestService } from "../../services/test";
import { GET_ALL_EXECUTED_TESTS_ADMIN } from "../../constants/endpoints/test";
import { setExecutedSelectedTest, setExecutedTestPagination, toggleCollapsed,
  //  toggleIsModalOpen
   } from "../../redux/slice/test";

const Report: React.FC = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    isCollapsed,
    selectedExecutedTest,
    executedTestData,
    executedTestPaginationData,
    // isModalOpen
  } = useSelector((state: RootState) => state.tests);
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllExecutedTests = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;

        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');
  
        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };

        const response = await getAllExecutedTestService(
          GET_ALL_EXECUTED_TESTS_ADMIN,
          paramObj
        );

        if (response.success) {
          if (q === "" || !q) {
            dispatch(setExecutedSelectedTest(response?.data?.data));
              dispatch(setExecutedTestPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              }));
            } else {
              dispatch(setExecutedSelectedTest(response?.data));
              dispatch(setExecutedTestPagination({
                totalCount: response?.data?.length,
                currentPage: 1,
                pageSize: 50
              }))
            }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN)
    ) {
      debouncedGetAllExecutedTests();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllExecutedTests]);

  const handleTabChange = (key: string) => {
    removeQueryParam("page");
    debouncedGetAllExecutedTests("", key, 1, 10, true);
  };

  const handleTableChange = (currentPage: number) => {
    setIsLoader(true)
    updateQueryParam("page", `${currentPage}`);
    debouncedGetAllExecutedTests(searchTerm, TabStatus.ALL, currentPage, executedTestPaginationData?.pageSize, true);
  };  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedGetAllExecutedTests(value);
  };

  // const handleModalToggle = () => {
  //   dispatch(toggleIsModalOpen(false));
  // };

  const handleView = (testData: TestInterface) => {
    console.log(selectedExecutedTest);
  }
  
  const columns = [
    {
      title: "S.No.",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Test Name",
      key: "testName",
      render: (testData: TestInterface) => testData?.testName,
    },
    {
      title: "Test Type",
      key: "testType",
      render: (testData: TestInterface) =>
        testData.testType || "-",
    },
    {
      title: "Total Slots",
      key: "totalSlots",
      render: (testData: TestInterface) =>
        testData.totalSlots || "-",
    },
    {
      title: "Filled Slots",
      key: "filledSlots",
      render: (testData: TestInterface) =>
        testData.filledSlots || "-",
    },
    {
      title: "Total Candidates",
      key: "candidates",
      render: (testData: TestInterface) =>
        testData?.candidates?.length|| "-",
    },
    {
      title: "Actions",
      key: "actions",
      render: (testData: TestInterface) => (
        <div className="action-section">
          <Tooltip title={"View Reports"}>
          <Button type="link" onClick={() => handleView(testData)}>
            <EyeTwoTone style={{ fontSize: "18px" }} />
          </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
            {user?.role === Roles.SUPER_ADMIN || user?.role === Roles?.ORG_ADMIN || user?.role === Roles?.INS_ADMIN ? (
        <div className={`test ${isCollapsed ? "collapsed" : "open"}`}>
          <Header title={"Executed Tests"} />
          <div className="head">
            <div>
              <Segmented
                defaultValue={TabStatus.ALL}
                onChange={handleTabChange}
                options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
              />
            </div>
            <div className="head-right">
              <Input
                className="antd-search-input-custom"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="table">
            <Table
              dataSource={executedTestData || []}
              columns={columns}
              rowKey={(record) => record._id}
              loading={!executedTestData || isLoader}
              pagination={{
                current: executedTestPaginationData?.currentPage,
                pageSize: executedTestPaginationData?.pageSize,
                total: executedTestPaginationData?.totalCount || 0, 
                onChange: handleTableChange,
              }}
              scroll={{ y: '61dvh' }}
            />
          </div>
        </div>
      ) : (
        <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>
      )}
    </div>
  );
};

export default Report;