import React from "react";
import { PendingCardProps } from "../../constants/interfaces/candidateTest";
import { Card, Tag, Button, Row, Col, Typography, Slider, Divider, Tooltip } from "antd";
import "./_testCard.scss";
import { formatDateInHrMinSec } from "../../utils/helpers";

const { Text } = Typography;

const PendingCard: React.FC<PendingCardProps> = ({ data, handleViewInstructionClick, onStart }) => {
  const startTimeFormatted = formatDateInHrMinSec(data?.startTime);

  const endTimeFormatted = formatDateInHrMinSec(data?.endTime);

  return (
    <Card
      title={
        <div className="card-title">
          <Tooltip title={data?.testName}><span className="ellipsis">{data?.testName}</span></Tooltip>
          <span>
            <Tag color="blue" className="test-type-tag">
              {data?.testType}
            </Tag>
          </span>
        </div>
      }
      bordered={false}
      className="test-card"
    >
      <Row gutter={[12, 8]}>
        <Col span={24}>
          {/* <ClockCircleOutlined className="icon" /> */}
          <Text strong className="text-strong">
            Start At:{" "}
          </Text>
          <Text>{startTimeFormatted}</Text>
        </Col>
        <Col span={24}>
          {/* <ClockCircleOutlined className="icon" /> */}
          <Text strong className="text-strong">
            Expires At:{" "}
          </Text>
          <Text>{endTimeFormatted}</Text>
        </Col>
        <Col span={24}>
          <Text strong className="text-strong">
            Total Marks:{" "}
            <Text className="highlight-text">{data?.totalMarks}</Text>
          </Text>
        </Col>
        <Col span={24}>
          <Text strong className="text-strong">
            Total Slots:{" "}
            <Text className="highlight-text">{data?.totalSlots}</Text>
          </Text>
        </Col>
        <Col span={24}>
          <Text strong className="text-strong">
            Filled Slots:{" "}
            <Text className="highlight-text">{data?.filledSlots}</Text>
          </Text>
        </Col>

        <Col span={24}>
          <Slider
            defaultValue={data?.filledSlots}
            min={0}
            max={data?.totalSlots}
            disabled
            tooltip={{ formatter: (value) => `${value}` }}
          />
          <Row justify="space-between">
            <span>0</span>
            <span>{data?.totalSlots}</span>
          </Row>
          <Divider />
        </Col>

        <Col span={24} className="button-container">
        <Button  type="primary" size="middle" shape="round" className="instruction-button" onClick={() => handleViewInstructionClick(data ? data?._id : '')}>
            View Instruction
        </Button>
          {data?.canStart && !data?.isJoined ? (
            <Button
              type="primary"
              size="middle"
              shape="round"
              className="start-button"
              onClick={() => onStart(data ? data?._id : '')}
            >
              Start Test
            </Button>
          ) : (
            <Button
              type="default"
              size="middle"
              shape="round"
              className="disabled-button"
              disabled
              onClick={() => onStart(data ? data?._id : '')}
            >
              {data?.isJoined ? "Already Joined" : "Not Available"}
            </Button>
          )}
   
        </Col>
      </Row>
    </Card>
  );
};

export default PendingCard;
