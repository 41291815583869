import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Switch, message } from "antd";
import { loginSchema } from "../../schemas/loginSchema";
import googleIcon from "../../assets/icons/google.png";
import loginImage from "../../assets/images/loginImage.png";
import dotImage from "../../assets/icons/Dot.svg";
import logo from "../../assets/icons/LogoDarkWihoutBG.svg";
import logoWhite from "../../assets/icons/LogoWhiteWihoutBg.svg";
import "./_login.scss";
import { ThemeContext } from "../../context/theme";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/slice/auth";
import { loginService, sendOtpService } from "../../services/auth";
import { AccountType, Roles } from "../../constants/enums/enums";
import { ADMIN_LOGIN, CANDIDATE_LOGIN, SEND_OTP_ADMIN, SEND_OTP_CANDIDATE } from "../../constants/endpoints/auth";

const Login: React.FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [accountType, setAccountType] = useState(AccountType.CANDIDATE);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [active, setActive] = useState(theme);
  const [isLoginLoader, setIsLoginLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(30);

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);

    try {
      await loginSchema.validateAt("email", { email: value });
      setValidationMessage("");
    } catch (err) {
      if (err instanceof Error) {
        setValidationMessage(err.message);
      }
    }
  };

  const handleOtpChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtp(value);

    try {
      await loginSchema.validateAt("otp", { otp: value });
      setValidationMessage("");
    } catch (err) {
      if (err instanceof Error) {
        setValidationMessage(err.message);
      }
    }
  };

  const sendOtp = async () => {
    try {
      await loginSchema.validateAt("email", { email });
      startTimer();
      const response = await sendOtpService(accountType === AccountType.ADMIN ? SEND_OTP_ADMIN : SEND_OTP_CANDIDATE, { email});
      if (response) {
        setValidationMessage("");
        setIsLoading(true);
        setIsOtpSent(true);
        message.success("Otp sent successfully!")
      }
    } catch (err) {

    }
  };

  const handleGoogleLogin = async () => {
    try {
      setIsGoogleLoading(true);
    } catch (err) {}
  };

  const handleLogin = async () => {
    try {
      await loginSchema.validateAt("otp", { otp });
      const payload = {
        email,
        otp
      }
      const response = await loginService(accountType === AccountType.ADMIN ? ADMIN_LOGIN : CANDIDATE_LOGIN, payload);
      if (response) {
        dispatch(loginSuccess({ user: response?.data}));
        setValidationMessage("");
        setIsLoginLoader(true);
        if(response?.data?.role === Roles.CANDIDATE) {
          navigate('/c/tests');
        } else {
          navigate('/dashboard');
        }
      }
    } catch (err) {}
  };

  const onSwitchChange = (checked: boolean) => {
    setAccountType(checked ? AccountType.ADMIN : AccountType.CANDIDATE);
  };

  const setTheme = (selected: string) => {
    setActive(selected);
    toggleTheme();
  };

  const startTimer = () => {
    setResendDisabled(true);
    setTimer(30);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setResendDisabled(false);
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer, isOtpSent]);

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-left-header">
          {active === "light" ? (
            <img src={logo} alt="Abc" className="main-logo" />
          ) : (
            <img src={logoWhite} alt="Abc" className="main-logo" />
          )}
          {!isOtpSent && (<div className="switch">
            <Switch
              onChange={onSwitchChange}
              value={accountType === AccountType.ADMIN ? true : false}
            />
            <h4>Admin</h4>
          </div>)}
        </div>
        <div className="login-left-main">
          <h2>Log in</h2>
          {isOtpSent ? (
            <p>Enter your one-time passcode.</p>
          ) : (
            <p>Enter your email to receive a one-time passcode.</p>
          )}
          {isOtpSent ? (
            <Input
              placeholder="Enter your otp"
              className="login-input"
              maxLength={4}
              minLength={4}
              onPressEnter={handleLogin}
              onChange={handleOtpChange}
            />
          ) : (
            <Input
              placeholder="Enter your email"
              className="login-input"
              maxLength={254}
              minLength={3}
              value={email}
              onPressEnter={sendOtp}
              onChange={handleEmailChange}
            />
          )}
          {validationMessage && (
            <p className="validation-message">{validationMessage}</p>
          )}

          {isOtpSent ? (
            <>
              <Button
                type="primary"
                className="login-button"
                onClick={handleLogin}
                loading={isLoginLoader}
              >
                Login
              </Button>
              <Button
                className="resend-button"
                onClick={sendOtp}
                disabled={resendDisabled}
              >
                {timer !== 0 ? `Resend Otp in ${timer}` : 'Resend Otp'}
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              className="login-button"
              onClick={sendOtp}
              loading={isLoading}
              disabled={isGoogleLoading}
            >
              Send 4-digit code
            </Button>
          )}
          <div className="or-divider">
            <div className="divider"></div>
            <div className="or">or</div>
            <div className="divider"></div>
          </div>
          <Button
            icon={<img src={googleIcon} alt="Google" className="google-icon" />}
            className="google-login"
            loading={isGoogleLoading}
            onClick={handleGoogleLogin}
            disabled={isLoading || isLoginLoader}
          >
            Sign in with Google
          </Button>
        </div>
        <div className="login-left-footer">
          <Button
            className={`toggle-button ${active === "light" ? "active" : ""}`}
            onClick={() => setTheme("light")}
            disabled={active === "light"}
          />
          <Button
            className={`toggle-button ${active === "dark" ? "active" : ""}`}
            onClick={() => setTheme("dark")}
            disabled={active === "dark"}
          />
        </div>
      </div>
      <div className="login-right">
        <div className="login-right-top">
          <div className="about">
            <div className="dot-image-parent">
              <img src={dotImage} alt="" className="dot-image" />
            </div>
            <span>{`What's ${process.env.REACT_APP_NAME}?`}</span>
          </div>
          <h3>Skills That Make a Difference</h3>
          <p>
            {`${process.env.REACT_APP_NAME} empowers success with skill assessments, helping individuals and organizations achieve their full potential.`}
          </p>
        </div>
        <img src={loginImage} alt="Login Here!" className="login-image" />
      </div>
    </div>
  );
};

export default Login;
