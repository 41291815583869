import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { message } from 'antd';
import { Ports } from '../config/config';
import { getBaseURL } from '../utils/helpers';

const createAxiosInstance = (service: keyof typeof Ports): AxiosInstance => {
  const baseURL = getBaseURL(service);

  const instance: AxiosInstance = axios.create({
    baseURL,
    timeout: 10000 * 6,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add request interceptor
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorage.getItem('authToken');
      if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response) {
        const status = error.response.status;
        const errorMessage = (error.response.data as { message?: string })?.message || 'An error occurred';

        if (status === 401 || status === 403) {
          message.error('You are not authorized please login!')
          setTimeout(() => window.location.replace('/login'), 3000);
          localStorage.clear();
        } else if (status === 500) {
          message.error('A server error occurred. Please try again later.');
        } else if (status === 555) {
          message.error('Please complete your profile first!');
          window.location.replace('/c/profile');
        } else {
          message.error(errorMessage);
        }
      } else if (error.request) {
        message.error('Unable to communicate with the server. Please check your network.');
      } else {
        message.error('An error occurred while setting up the request.');
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;