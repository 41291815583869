import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrganizationState } from "../../constants/interfaces/organization";
import { PaginationInterface } from "../../constants/interfaces/common";

const initialState: OrganizationState = {
    organizationData: null,
    isCollapsed: false,
    isModalOpen: false,
    selectedOrganization: null,
    isViewing: false,
    isAddOrganization: false,
    paginationData: undefined,
  };
  
  const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
      setOrganizations: (state, action: PayloadAction<any[]>) => {
        state.organizationData = action.payload;
      },
      toggleCollapsed: (state, action: PayloadAction<boolean>) => {
        state.isCollapsed = action.payload;
      },
      toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
        state.isModalOpen =  action.payload;
      },
      setSelectedOrganization: (state, action: PayloadAction<any | null>) => {
        state.selectedOrganization = action.payload;
      },
      setIsViewing: (state, action: PayloadAction<boolean>) => {
        state.isViewing = action.payload;
      },
      removeOrganization: (state, action: PayloadAction<string>) => {
        state.organizationData = state.organizationData?.filter(
          (organization) => organization._id !== action.payload
        );
      },
      setIsAddOrganization: (state, action: PayloadAction<boolean>) => {
        state.isAddOrganization = action.payload;
      },
      setPagination: (state, action: PayloadAction<PaginationInterface>) => {
        state.paginationData = action.payload;
      }
    },
  });
  
  export const { toggleCollapsed, toggleIsModalOpen, setSelectedOrganization, setIsViewing, removeOrganization, setIsAddOrganization, setOrganizations, setPagination } = organizationsSlice.actions;
  
  export default organizationsSlice.reducer;