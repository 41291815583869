import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_institute.scss";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Roles, TabStatus } from "../../constants/enums/enums";
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import {
  removeInstitute,
  setIsAddInstitute,
  setIsViewing,
  setPagination,
  setSelectedInstitute,
  toggleCollapsed,
  toggleIsModalOpen,
} from "../../redux/slice/institutes";
import { debounce } from "lodash";
import {
  addInstituteService,
  deleteInstituteService,
  getAllInstituteService,
} from "../../services/institute";
import {
  GET_ALL_INSTITUTE_ADMIN,
  DELETE_INSTITUTE_ADMIN,
  ADD_INSTITUTE_ADMIN,
} from "../../constants/endpoints/institute";
import { setInstitutes } from "../../redux/slice/institutes";
import { Button, Input, Modal, Segmented, Table, message } from "antd";

import { EyeTwoTone, DeleteFilled } from "@ant-design/icons";
import InstituteModal from "../../components/Modals/InstituteModal/AddViewInstituteModal";
import { InstituteInterface } from "../../constants/interfaces/institute";
import useQueryParams from "../../utils/useQueryParams";

const Institute: React.FC = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    instituteData,
    isCollapsed,
    isViewing,
    selectedInstitute,
    isModalOpen,
    isAddInstitute,
    paginationData
  } = useSelector((state: RootState) => state.institutes);
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllInstitutes = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;

        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');
  
        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };

        const response = await getAllInstituteService(
          GET_ALL_INSTITUTE_ADMIN,
          paramObj
        );
        if (response.success) {
          if (q === "" || !q) {
            dispatch(setInstitutes(response?.data?.data));
              dispatch(setPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              }));
            } else {
              dispatch(setInstitutes(response?.data));
              dispatch(setPagination({
                totalCount: response?.data?.length,
                currentPage: 1,
                pageSize: 50
              }))
            }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN)
    ) {
      debouncedGetAllInstitutes();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllInstitutes]);

  const handleDelete = async (institute: InstituteInterface) => {
    try {
      setIsLoader(true);
      const response = await deleteInstituteService(
        `${DELETE_INSTITUTE_ADMIN.replace(":id", institute._id)}`
      );

      if (response?.success) {
        dispatch(removeInstitute(institute._id));
        message.success("institute removed successfully!");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.error("Failed to delete institute", err);
    }
  };

  const confirmDelete = (institute: InstituteInterface) => {
    Modal.confirm({
      title: "Are you sure you want to delete this institute?",
      onOk: () => handleDelete(institute),
      okText: "Yes",
      cancelText: "No",
      centered: true,
    });
  };

  const handleTabChange = (key: string) => {
    removeQueryParam("page");
    debouncedGetAllInstitutes("", key, 1, 10, true);
  };

  const handleTableChange = (currentPage: number) => {
    setIsLoader(true)
    updateQueryParam("page", `${currentPage}`);
    debouncedGetAllInstitutes(searchTerm, TabStatus.ALL, currentPage, paginationData?.pageSize, true);
  };  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedGetAllInstitutes(value);
  };

  const handleAddInstitute = () => {
    dispatch(setSelectedInstitute(null));
    dispatch(setIsAddInstitute(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleView = (institute: InstituteInterface) => {
    dispatch(setSelectedInstitute(institute));
    dispatch(setIsViewing(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleModalToggle = () => {
    isViewing && dispatch(setIsViewing(false));
    isAddInstitute && dispatch(setIsAddInstitute(false));
    dispatch(toggleIsModalOpen(false));
  };

  const handleSubmit = async (values: InstituteInterface) => {
    try {
      setIsLoader(true);
      const response = await addInstituteService(ADD_INSTITUTE_ADMIN, values);
      if (response.success) {
        message.success("Institute added successfully!");
        debouncedGetAllInstitutes();
        handleModalToggle();
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
    }
  };

  const columns = [
    {
      title: "S.No.",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Name",
      key: "name",
      render: (instituteData: InstituteInterface) => instituteData.name,
    },
    {
      title: "Email",
      key: "email",
      render: (instituteData: InstituteInterface) => instituteData.email,
    },
    {
      title: "Employee Count",
      key: "count",
      render: (instituteData: InstituteInterface) =>
        `${instituteData?.studentCount || "-"}`,
    },
    {
      title: "Contact",
      key: "contact",
      render: (instituteData: InstituteInterface) =>
        `${instituteData?.phone || "-"}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (instituteData: InstituteInterface) => (
        <div className="action-section">
          <Button type="link" onClick={() => handleView(instituteData)}>
            <EyeTwoTone style={{ fontSize: "18px" }} />
          </Button>
          <Button type="link" onClick={() => confirmDelete(instituteData)}>
            <DeleteFilled style={{ fontSize: "18px", color: "red" }} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      {user?.role === Roles.SUPER_ADMIN ? (
        <div className={`institute ${isCollapsed ? "collapsed" : "open"}`}>
          <Header title={"Institutes"} />
          <div className="head">
            <div>
              <Segmented
                defaultValue={TabStatus.ALL}
                onChange={handleTabChange}
                options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
              />
            </div>
            <div className="head-right">
              <Input
                className="antd-search-input-custom"
                placeholder="Search institutes"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {/* <Button
                type="primary"
                className="button"
                onClick={handleBulkUpload}
              >
                Bulk Add Candidate
              </Button> */}
              <Button
                type="primary"
                className="button"
                onClick={handleAddInstitute}
              >
                Add Institute
              </Button>
            </div>
          </div>
          <div className="table">
            <Table
              dataSource={instituteData || []}
              columns={columns}
              rowKey={(record) => record._id}
              loading={!instituteData || isLoader}
              pagination={{
                current: paginationData?.currentPage,
                pageSize: paginationData?.pageSize,
                total: paginationData?.totalCount || 0, 
                onChange: handleTableChange,
              }}
              scroll={{ y: '61dvh' }}
            />
          </div>
        </div>
      ) : (
        <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>
      )}
      <InstituteModal
        isViewing={isViewing}
        isModalOpen={isModalOpen}
        isAddInstitute={isAddInstitute}
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        selectedInstitute={selectedInstitute}
      />
    </div>
  );
};

export default Institute;