import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Collapse,
  Empty,
  List,
  Checkbox,
  Tooltip,
  Switch,
} from "antd";
import "./_testForm.scss";
import { Stage1Values, TestFormProps } from "../../../constants/interfaces/test";
import Search from "antd/es/input/Search";
import { disabledDate, disabledDateTime, htmlToString } from "../../../utils/helpers";
import { DeleteFilled } from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment";
import { InstituteInterface } from "../../../constants/interfaces/institute";
import { TestStage, TestType } from "../../../constants/enums/enums";

const TestForm: React.FC<TestFormProps> = ({
  onSubmit,
  onCancel,
  handleSectionAdd,
  handleSectionChange,
  handleDeleteSection,
  addDataToSection,
  handleCheckboxChange,
  setSelectedSection,
  handleIndividualQuestion,
  debouncedgetAllQuestion,
  setCurrentStage,
  organizationData,
  selectedIds,
  initialValues,
  candidates,
  inputValue,
  questionData,
  sectionData,
  isEditTest,
  isViewing,
  instituteData,
  currentStage,
}) => {
  const [testType, setTestType] = useState("");
  const [filteredCandidates,setFilteredCandidates] = useState(candidates);
  const [stage1Values, setStage1Values] = useState<Stage1Values | null>(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleCandidateSearch = debounce(async (query: string) => {
    if (query.length < 3) {
      const formData = await form.getFieldsValue();
      if (formData.organization) {
        const candidateList = candidates?.filter(candidate => (candidate?.organization?._id === formData?.organization));
        setFilteredCandidates(candidateList);
      } else if (formData.institute) {
        const candidateList = candidates?.filter(candidate => (candidate?.institute?._id === formData?.institute));
        setFilteredCandidates(candidateList);
      } else if (formData.testType !== TestType.PROFICIENCY || formData.testType !== TestType.ASSESSMENT) {
        setFilteredCandidates(candidates);
      }
        return;
      }
      const fetchedCandidates = filteredCandidates?.filter(candidate => 
        candidate?.skills?.some(skill => 
          skill?.name?.toLowerCase()?.includes(query.toLowerCase())
        )
      ) || [];      
          setFilteredCandidates(fetchedCandidates);
  }, 1000);  


  const handleCandidates = useCallback(
    (id: string, type: string) => {
      if (type === TestType.PROFICIENCY) {
        const filteredCandidates = candidates?.filter(candidate => candidate?.organization?._id === id);
        setFilteredCandidates(filteredCandidates);
      } else if (type === TestType.ASSESSMENT) {
        const filteredCandidates = candidates?.filter(candidate => candidate?.institute?._id === id);
        setFilteredCandidates(filteredCandidates);
      } else {
        setFilteredCandidates(candidates);
      }
    },
    [candidates, setFilteredCandidates]
  );

  useEffect(() => {
    if(!isEditTest || !isViewing) form.resetFields();
    if (isEditTest || isViewing) {
      handleCandidates(initialValues?.organization ? initialValues?.organization?._id : initialValues?.institute?._id , initialValues?.testType)
    }
    setTestType(initialValues?.testType ? initialValues.testType : 'Proficiency Test');
  }, [initialValues, form, isEditTest, isViewing, candidates, handleCandidates]);

  const handleTestTypeChange = (value: string) => {
    handleCandidates('', value);
    setTestType(value);
    form.resetFields([
      "organization",
      "institute",
      "startTime",
      "entryTime",
      "candidates",
      "shuffleSection",
      "shuffleQuestion"
    ]);
  };

  const handleSubmit = async () => {
    try {
      let values = await form.validateFields();
      values = stage1Values?.candidates?.length && stage1Values?.candidates?.length > 0
      ? { ...values, prefilledCandidates: "true", ...(stage1Values || {}) }
      : { ...values, prefilledCandidates: "false", ...(stage1Values || {}) };
      onSubmit(values);
      form.resetFields();
      setStage1Values(null);
      setFilteredCandidates([]);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const nextStep = async () => {
    try {
      const values = await form.validateFields();
      setCurrentStage(TestStage.SECOND);
      setStage1Values(values);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const previousStep = () => {
    setCurrentStage(TestStage.FIRST);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleQuestion = useCallback(
    debounce((value, key, action, quesId) => {
      handleIndividualQuestion(value, key, action, quesId);
    }, 1000),
    [handleIndividualQuestion]
  );

  return (
    <Form form={form} layout="vertical" className="test-form" initialValues={{
        testType: initialValues?.testType ? initialValues?.testType : "Proficiency Test",
        testName: initialValues?.testName ? initialValues?.testName : undefined,
        candidates: initialValues?.candidates?.length > 0 ? initialValues?.candidates?.map((i: any) => i._id) : undefined,
        startTime: initialValues?.startTime
          ? moment(initialValues.startTime)
          : null,
        entryTime: initialValues?.entryTime ? initialValues?.entryTime : undefined,
        organization: initialValues?.organization?._id ? initialValues?.organization?._id : undefined,
        institute: initialValues?.institute?._id ? initialValues?.institute?._id : undefined,
        shuffleSection: initialValues?.shuffleSection ? initialValues?.shuffleSection  : false,
        shuffleQuestion: initialValues?.shuffleQuestion ? initialValues?.shuffleQuestion : false,
        totalSlots: initialValues?.totalSlots ? initialValues?.totalSlots : undefined
    }}>
      {currentStage === TestStage.FIRST && (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={8} lg={8}>
              <Form.Item
                label="Name"
                name="testName"
                rules={[
                  { required: true, message: "Please input the name!" },
                  {
                    min: 5,
                    message: "Name must be at least 5 characters long!",
                  },
                ]}
              >
                <Input placeholder="Enter name" minLength={5} disabled={isViewing}/>
              </Form.Item>
            </Col>

            <Col xs={24} sm={8} md={8} lg={8}>
              <Form.Item
                label="Type"
                name="testType"
                rules={[
                  { required: true, message: "Please select the test type!" },
                ]}
              >
                <Select onChange={handleTestTypeChange} disabled={isViewing}>
                  <Option value="Proficiency Test">Proficiency Test</Option>
                  <Option value="Assessment">Assessment</Option>
                  <Option value="Competition">Competition</Option>
                  <Option value="Mock Test">Mock Test</Option>
                </Select>
              </Form.Item>
            </Col>

            {testType === TestType.PROFICIENCY && (
              <Col xs={24} sm={8} md={8} lg={8}>
                <Form.Item
                  label="Organization"
                  name="organization"
                  rules={[
                    {
                      required: true,
                      message: "Please select an organization!",
                    },
                  ]}
                >
                  <Select placeholder="Select organization" allowClear onChange={(value) => handleCandidates(value, TestType.PROFICIENCY)}  disabled={isViewing}>
                    {organizationData?.map((org) => (
                      <Option key={org._id} value={org._id}>
                        {org.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {testType === TestType.ASSESSMENT && (
              <Col xs={24} sm={8} md={8} lg={8}>
                <Form.Item
                  label="Institute"
                  name="institute"
                  rules={[
                    { required: true, message: "Please select an institute!" },
                  ]}
                >
                  <Select placeholder="Select institute" onChange={(value) => handleCandidates(value, TestType.ASSESSMENT)} allowClear  disabled={isViewing}>
                    {instituteData?.map((inst: InstituteInterface) => (
                      <Option key={inst._id} value={inst._id}>
                        {inst.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} className="candidate-parent">
              <div className="candidate-heading">
              Candidates
              </div>
              <Input
              onChange={(e) => handleCandidateSearch(e?.target?.value)}
              placeholder="Search Candidate based on skills"
              />
              {filteredCandidates?.length && filteredCandidates?.length > 0 ? (<Form.Item
                name="candidates"
                rules={[
                  { required: false, message: "Please select Candidates!" },
                ]}
              >
                <Checkbox.Group
                className="checkbox-group-candidate"
              >
                {filteredCandidates?.map((candidate) => (
                  <Checkbox key={candidate._id} value={candidate._id}  disabled={isViewing} className="global-text-color">
                    {`${candidate.firstName} ${candidate.lastName}`}
                  </Checkbox>
                ))}
              </Checkbox.Group>
                {/* <Select placeholder="Select Candidate" mode="multiple">
                  {candidates?.map((candidate) => (
                    <Option key={candidate?._id} value={candidate?._id}>
                      {`${candidate?.firstName} ${candidate?.lastName}`}
                    </Option>
                  ))}
                </Select> */}
              </Form.Item>) : <Empty className="candidate-empty-box"/>}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={8} md={8} lg={8}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  { required: true, message: "Please select a start time" },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Select start time"
                  disabledDate={disabledDate}
                  disabledTime={disabledDateTime}
                  disabled={isViewing}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8}>
              <Form.Item
                label="Entry Time"
                name="entryTime"
                rules={[
                  { required: true, message: "Please enter the entry time" },
                ]}
              >
                <Input min={0} type="number" placeholder="In seconds" disabled={isViewing}/>
              </Form.Item>
            </Col>
            {(
              <Col xs={24} sm={8} md={8} lg={8}>
                <Form.Item label="Total Slots" name="totalSlots" rules={[{required: true, message: 'Total slots are required'}]}>
                  <Input placeholder="Enter total slots..." disabled={isViewing}/>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24} sm={3} md={3} lg={3}>
              <Form.Item
                label={<span className="global-text-color">Shuffle Section</span>}
                name="shuffleSection"
                rules={[]}
              >
                <Switch disabled={isViewing}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={3} md={3} lg={3}>
              <Form.Item
                label={<span className="global-text-color">Shuffle Question</span>}
                name="shuffleQuestion"
                rules={[]}
              >
                <Switch disabled={isViewing}/>
              </Form.Item>
            </Col>
          </Row>
          <div className="next-button">
            <Button type="primary" onClick={nextStep}>
              Next
            </Button>
          </div>
        </>
      )}

      {currentStage === TestStage.SECOND && (
        <>
          <div className="ques-section-parent">
            <div className="question outline">
              <div className="question-header">
                <h3 className="custom-text-color">Questions</h3>
                <Input
                  placeholder="Search for a question!"
                  className="search"
                  onChange={debouncedgetAllQuestion}
                  disabled={isViewing}
                />
              </div>
              <div className="question-list">
                <List
                  dataSource={questionData}
                  renderItem={(item: any) => (
                  <List.Item>
                    {!isViewing && (
                      <Checkbox
                        onChange={(e) =>
                          handleCheckboxChange(e.target.checked, item._id)
                        }
                        checked={selectedIds.includes(item._id)}
                      />
                    )}
                    {!isViewing ? (<Tooltip placement="top" title={htmlToString(item?.quesText)}>
                      <div
                        className="ellipsis add-width custom-text-color custom-cursor"
                        onClick={() =>
                          handleCheckboxChange(
                            !selectedIds.includes(item._id),
                            item._id
                          )
                        }
                      >
                        {htmlToString(item.quesText)}
                      </div>
                    </Tooltip>) : <></>}
                  </List.Item>                  
                  )}
                />
              </div>
              {!isViewing && (
                <div className="question-footer">
                  <Select
                    className="select"
                    onChange={(value) => setSelectedSection(value)}
                  >
                    {sectionData &&
                      sectionData?.map((item: any, index) => (
                        <Option key={index} value={item?.key}>
                          {item?.sectionName}
                        </Option>
                      ))}
                  </Select>
                  <Button
                    type="primary"
                    className="add-button"
                    onClick={addDataToSection}
                    disabled={selectedIds.length === 0}
                  >
                   <span className="global-text-color"> Add</span>
                  </Button>
                </div>
              )}
            </div>
            <div className="section outline">
              {!isViewing ? (
                <div className="section-header">
                  <Search
                    placeholder="Add section name!"
                    allowClear
                    enterButton="Add Section"
                    size="large"
                    onSearch={handleSectionAdd}
                    value={inputValue}
                    onChange={handleSectionChange}
                    className="add-section-button"
                    inputPrefixCls="add-section-button-placeholder"
                    maxLength={50}
                    minLength={5}
                  />
                </div>
              ) : (
                <h3>Sections</h3>
              )}

              {sectionData && sectionData.length > 0 ? (
                <div className="section-list">
                  <Collapse>
                    {sectionData.map((item: any, index: number) => (
                      <Collapse.Panel
                        header={handleDeleteSection(item.sectionName, item.key)}
                        key={item.key}
                      >
                        <List
                          dataSource={item?.questions}
                          renderItem={(item: any) => (
                            <List.Item className="list-item">
                              <Tooltip
                                placement="top"
                                title={htmlToString(
                                  item?.questionData?.quesText ? item?.questionData?.quesText : item?.quesText
                                )}
                              >
                                <div className="ellipsis custom-width">
                                  {htmlToString(item?.questionData?.quesText ? item?.questionData?.quesText : item?.quesText)}
                                </div>
                              </Tooltip>
                              <div className="list-action">
                                <Input
                                  className="list-item-input"
                                  type="number"
                                  placeholder="Time"
                                  defaultValue={item?.time}
                                  onChange={(e) =>
                                    debouncedHandleQuestion(
                                      e.target.value,
                                      sectionData[index]?.key,
                                      "time",
                                      item?.question
                                    )
                                  }
                                  disabled={isViewing}
                                />
                                <Input
                                  className="list-item-input"
                                  type="number"
                                  placeholder="Marks"
                                  defaultValue={item?.marks}
                                  onChange={(e) =>
                                    debouncedHandleQuestion(
                                      e.target.value,
                                      sectionData[index]?.key,
                                      "marks",
                                      item?.question
                                    )
                                  }
                                  disabled={isViewing}
                                />
                                <DeleteFilled
                                  style={{ color: "red" }}
                                  onClick={() =>
                                    handleIndividualQuestion(
                                      "",
                                      sectionData[index]?.key,
                                      "delete",
                                      item?.question
                                    )
                                  }
                                />
                              </div>
                            </List.Item>
                          )}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </div>
              ) : (
                <Empty />
              )}
            </div>
          </div>
          <div className="form-actions">
            <Button className="cancel-button" onClick={previousStep}>
              Previous
            </Button>
            <Button className="cancel-button" onClick={onCancel}>
              Cancel
            </Button>
            {!isViewing && (
              <Button
                type="primary"
                className="submit-button"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </div>
        </>
      )}
    </Form>
  );
};

export default TestForm;