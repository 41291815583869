import { ParamObj } from "../constants/interfaces/common";
import { TagInterface } from "../constants/interfaces/tag";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const TagAxios = createAxiosInstance('TAG');

export const getAllTagService = async (url: string, payload?: ParamObj) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await TagAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteTagService = async (url: string) => {
    try {
        const response = await TagAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const addTagService = async (url: string, payload: TagInterface) => {
    try {
        const response = await TagAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}