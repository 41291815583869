import React, { useCallback, useEffect, useRef, useState } from "react";
import "./_test.scss";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { removeTest, setCandidateData, setIsAddTest, setIsEditTest, setIsViewing, setOrganizationData, setQuestionData, setSectionData, setSelectedTest, setTestData, toggleCollapsed, toggleIsModalOpen, setInstituteData, setPagination, setUsedQuestions } from "../../redux/slice/test";
import { Roles, TabStatus, TestStage } from "../../constants/enums/enums";
import { debounce } from "lodash";
import { addTestService, deleteTestService, editTestService, getAllTestService } from "../../services/test";
import { ADD_TEST_ADMIN, EDIT_DELETE_TEST_ADMIN, GET_ALL_TESTS_ADMIN } from "../../constants/endpoints/test";
import { TestInterface } from "../../constants/interfaces/test";
import { Button, Input, Modal, Popconfirm, Segmented, Table, message } from "antd";
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";
import {EyeTwoTone, DeleteFilled, EditOutlined} from "@ant-design/icons"
import { getAllQuestionService } from "../../services/question";
import { GET_ALL_QUESTIONS_ADMIN } from "../../constants/endpoints/question";
import TestModal from "../../components/Modals/TestModal/AddEditTestModal";
import { getAllCandidateService } from "../../services/candidate";
import { GET_ALL_CANDIDATES_ADMIN } from "../../constants/endpoints/candidate";
import { getAllOrganizaionService } from "../../services/organization";
import { GET_ALL_ORGANIZATIONS_ADMIN } from "../../constants/endpoints/organization";
import { getAllInstituteService } from "../../services/institute";
import { GET_ALL_INSTITUTE_ADMIN } from "../../constants/endpoints/institute";
import useQueryParams from "../../utils/useQueryParams";

const Test: React.FC = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [currentStage, setCurrentStage] = useState(TestStage.FIRST);

  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    isCollapsed,
    testData,
    selectedTest,
    questionData,
    isViewing,
    isEditTest,
    isAddTest,
    isModalOpen,
    candidateData,
    sectionData,
    organizationData,
    instituteData, 
    paginationData,
    usedQuestions,
  } = useSelector((state: RootState) => state.tests);
  const { updateQueryParam, removeQueryParam, getQueryParam } = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllTests = useCallback(
    debounce(async (q = "", selectedTab = TabStatus.ALL, page = 1, limit = 10, isUpdated = false) => {
      try {
        setIsLoader(true);
        let isDeleted;
        if (selectedTab === TabStatus.ACTIVE) isDeleted = false;
        if (selectedTab === TabStatus.INACTIVE) isDeleted = true;

        const pageQuery = getQueryParam("page");
        const currentPage = isUpdated ? page : parseInt(pageQuery ? pageQuery : '1');
  
        let paramObj = {
          sortOrder: -1,
          ...(isDeleted === false || isDeleted === true ? { isDeleted } : {}),
          ...(q && q !== "" ? { q } : {
            paginate: true,
            page: currentPage,
            limit,
          }),
        };

        const response = await getAllTestService(
          GET_ALL_TESTS_ADMIN,
          paramObj
        );
        if (response.success) {
          if (q === "" || !q) {
            dispatch(setTestData(response?.data?.data));
              dispatch(setPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              }));
            } else {
              dispatch(setTestData(response?.data));
              dispatch(setPagination({
                totalCount: response?.data?.length,
                currentPage: 1,
                pageSize: 50
              }))
            }
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 500),
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedgetAllQuestion = useCallback(
    debounce(async (e) => {
      const paramObj = {
        isDeleted: false,
        ...(e?.target?.value && e?.target?.value !== "" ? { q: e?.target?.value } : {}),
      };
      const response = await getAllQuestionService(GET_ALL_QUESTIONS_ADMIN, paramObj);
      if (response?.success) {
        dispatch(setQuestionData(response?.data));
      }
    }, 500),
    [dispatch]
  ); 

  const fetchAllQuestionsAndCandidates = useCallback(async () => {
    try {
      const paramObj = {
        isDeleted: false,
      }
      const response = await getAllQuestionService(
        GET_ALL_QUESTIONS_ADMIN, paramObj
      );
      if (response?.success) {
        dispatch(setQuestionData(response?.data));
      }
      const candidateResponse = await getAllCandidateService(
        GET_ALL_CANDIDATES_ADMIN, paramObj
      )
      if (candidateResponse?.success) {
        dispatch(setCandidateData(candidateResponse?.data));
      }
      const organizationResponse = await getAllOrganizaionService(GET_ALL_ORGANIZATIONS_ADMIN, paramObj);
      if (organizationResponse?.success) {
        dispatch(setOrganizationData(organizationResponse?.data));
      }
      const instituteResponse = await getAllInstituteService(GET_ALL_INSTITUTE_ADMIN, paramObj);
      if (organizationResponse?.success) {
        dispatch(setInstituteData(instituteResponse?.data));
      }
    } catch (err) {}
  }, [dispatch]);

  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.SUPER_ADMIN || user?.role === Roles.ORG_ADMIN || Roles.INS_ADMIN)
    ) {
      debouncedGetAllTests();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllTests]);

  const handleDelete = async (test: TestInterface) => {
    try {
      setIsLoader(true);
      const response = await deleteTestService(
        `${EDIT_DELETE_TEST_ADMIN.replace(":id", test._id)}`
      );

      if (response?.success) {
        dispatch(removeTest(test._id));
        message.success("Test removed successfully!");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.error("Failed to delete Test", err);
    }
  };

  const confirmDelete = (test: TestInterface) => {
    Modal.confirm({
      title: "Are you sure you want to delete this test?",
      onOk: () => handleDelete(test),
      okText: "Yes",
      cancelText: "No",
      centered: true,
    });
  };

  const handleTabChange = (key: string) => {
    removeQueryParam("page");
    debouncedGetAllTests("", key, 1, 10, true);
  };

  const handleTableChange = (currentPage: number) => {
    setIsLoader(true)
    updateQueryParam("page", `${currentPage}`);
    debouncedGetAllTests(searchTerm, TabStatus.ALL, currentPage, paginationData?.pageSize, true);
  };  

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedGetAllTests(value);
  };

  const handleAddTest = () => {
    fetchAllQuestionsAndCandidates();
    dispatch(setSelectedTest(null));
    dispatch(setIsAddTest(true));
    dispatch(toggleIsModalOpen(true));
  };

  const handleView = (test: TestInterface) => {
    dispatch(setSelectedTest(test));
    dispatch(setIsViewing(true));
    dispatch(toggleIsModalOpen(true));
    const updatedSection = test.sections?.map((section) => {
      return {
        ...section,
        key: section?.sectionName,
        questions: section.questions?.map((ques: any) => ({
          ...ques,
          quesText: ques?.question?.quesText,
          question: ques?.question?._id
        }))
      };
    });
    dispatch(setSectionData(updatedSection));
  };

  const handleModalToggle = async () => {
    if (isViewing) dispatch(setIsViewing(false));
    if (isAddTest) dispatch(setIsAddTest(false));
    if (isEditTest) dispatch(setIsEditTest(false));
    setCurrentStage(TestStage.FIRST);
    dispatch(toggleIsModalOpen(false));
    dispatch(setSectionData([]));
    await fetchAllQuestionsAndCandidates()
  };
  const handleEdit = async (test: TestInterface) => {
    fetchAllQuestionsAndCandidates()
    dispatch(setSelectedTest(test));
    dispatch(setIsEditTest(true));
    dispatch(toggleIsModalOpen(true));
    const updatedSection = test.sections?.map((section) => {
      return {
        ...section,
        key: section?.sectionName,
        questions: section.questions?.map((ques: any) => ({
          ...ques,
          quesText: ques?.question?.quesText,
          question: ques?.question?._id
        }))
      };
    });
    dispatch(setSectionData(updatedSection));
  };

  const handleSubmit = async (values: TestInterface) => {
    try {
      const updatedValues = {...values, sections: sectionData};
      const selectedTestId = selectedTest?._id ? selectedTest?._id : '';
      setIsLoader(true);
      const response = isEditTest ? await editTestService(EDIT_DELETE_TEST_ADMIN.replace(':id', selectedTestId), updatedValues) : await addTestService(ADD_TEST_ADMIN, updatedValues);
      if (response.success) {
        isEditTest && message.success("Test updated successfully!");
        isAddTest && message.success("Test added successfully!");
        debouncedGetAllTests();
        handleModalToggle();
        dispatch(setSectionData([]));
        setCurrentStage(TestStage.FIRST);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      handleModalToggle();
    }
  };

  const handleCheckboxChange = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  const handleSectionAdd = (value: string) => {
    if (value.length < 5) {
      message.error("Minimum length must be 5 for the section name!");
      return;
    }
   const sectionExist = sectionData?.filter(section => section?.sectionName === value)
    if (sectionExist && sectionExist.length > 0) {
      message.error("Section name must be unique!");
      return;
    }

    dispatch(setSectionData([
      ...(sectionData || []), {
        key: value,
        sectionName: value,
        questions: []
      }
    ]))

    message.success('Section added successfully!')
    setInputValue("");
  };

  const handleDeleteSection = (key: string) => {
    if (isViewing) {
      message.error('Action not permissible while viewing!')
      return;
    }
    const selectedSection = sectionData?.find(item => item?.key === key);
    const questionIds =  selectedSection?.questions?.map(item => item?.question);
    
    const filteredUsedQuestions = usedQuestions?.filter(item => !questionIds?.includes(item._id));
    filteredUsedQuestions ? dispatch(setUsedQuestions(filteredUsedQuestions)) : dispatch(setUsedQuestions([]));

    const updatedItems = sectionData && sectionData.filter((item: any) => item.key !== key);
    dispatch(setSectionData(updatedItems));
  };

  const renderLabelWithDelete = (value: string, key: string) => (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <span>{value}</span>
      <Popconfirm
        title="Are you sure you want to delete this section?"
        onConfirm={() => handleDeleteSection(key)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteFilled style={{ color: "red", marginLeft: "10px", cursor: "pointer" }} />
      </Popconfirm>
    </div>
  );  

  const handleSectionChange = (e: any) => {
    setInputValue(e?.target?.value);
  }

  const addDataToSection = () => {
    const itemToChangeIndex = sectionData?.findIndex(item => item?.sectionName === selectedSection);
    if (itemToChangeIndex === -1 || itemToChangeIndex === undefined) {
      message.error('Please select a section!');
      return;
    }
  
    const previousQuestions = sectionData?.[itemToChangeIndex]?.questions || [];
  
    const newQuestions = selectedIds
      ?.filter(id => !previousQuestions.some(question => question?.question === id))
      ?.map(id => ({
        question: id,
        questionData: questionData?.find(question => question?._id === id),
        marks: 1,
        time: 60,
      }));
  
    const updatedSection = {
      key: sectionData?.[itemToChangeIndex]?.key,
      sectionName: sectionData?.[itemToChangeIndex]?.sectionName,
      questions: [
        ...previousQuestions,
        ...newQuestions
      ]
    };
  
    const updatedSectionData = sectionData?.map((item, index) =>
      index === itemToChangeIndex ? updatedSection : item
    );

    const addedQuestions = questionData?.filter(question => selectedIds?.includes(question?._id));
    setSelectedIds([]);
    dispatch(setSectionData(updatedSectionData));
    dispatch(setUsedQuestions([...(usedQuestions || []), ...(addedQuestions || [])]));
    message.success('Questions updated successfully!');
  };  

  const handleIndividualQuestion = (value: any, key: string, action: string, quesId: string) => {
    if (isViewing) {
      message.error('Action not permissible while viewing!')
      return;
    }
    const itemToChangeIndex = sectionData?.findIndex(item => item?.key === key); 
    if (itemToChangeIndex === -1 || itemToChangeIndex === undefined) {
      message.error('Question could not be updated!');
      return;
    }
  
    const previousQuestions = sectionData?.[itemToChangeIndex]?.questions || [];
  
    const updatedQuestions = previousQuestions.map(question => {
      if (question.question === quesId) {
        if (action === 'time') {
          return { ...question, time: parseInt(value) };
        } else if (action === 'marks') {
          return { ...question, marks: parseInt(value) }
        }
      }
      return question;
    }).filter(question => !(action === 'delete' && question.question === quesId));
    
    const updatedSection = {
      ...sectionData?.[itemToChangeIndex],
      questions: updatedQuestions
    };
  
    const updatedSectionData = sectionData?.map((item, index) =>
      index === itemToChangeIndex ? updatedSection : item
    );

    const filteredUsedQuestions = usedQuestions?.filter(item => quesId !== item?._id);
    action === 'delete' && filteredUsedQuestions && dispatch(setUsedQuestions(filteredUsedQuestions));
  
    dispatch(setSectionData(updatedSectionData));
    message.success(`${(action === 'time' && 'Time updated!') || (action === 'marks' && 'Mark updated!') || (action === 'delete' && 'Question deleted!')}`)
  };
  
  const columns = [
    {
      title: "S.No.",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Test Name",
      key: "testName",
      render: (testData: TestInterface) => testData?.testName,
    },
    {
      title: "Test Type",
      key: "testType",
      render: (testData: TestInterface) =>
        testData.testType || "-",
    },
    {
      title: "Total Slots",
      key: "totalSlots",
      render: (testData: TestInterface) =>
        testData.totalSlots || "-",
    },
    {
      title: "Filled Slots",
      key: "filledSlots",
      render: (testData: TestInterface) =>
        testData.filledSlots || "-",
    },
    {
      title: "Total Candidates",
      key: "candidates",
      render: (testData: TestInterface) =>
        testData?.candidates?.length|| "-",
    },
    {
      title: "Actions",
      key: "actions",
      render: (testData: TestInterface) => (
        <div className="action-section">
          <Button type="link" onClick={() => handleView(testData)}>
            <EyeTwoTone style={{ fontSize: "18px" }} />
          </Button>
          <Button type="link" onClick={() => handleEdit(testData)}>
            <EditOutlined style={{ fontSize: "18px" }} />
          </Button>
          <Button type="link" onClick={() => confirmDelete(testData)}>
          <DeleteFilled style={{ fontSize: "18px", color: 'red' }} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
            {user?.role === Roles.SUPER_ADMIN ? (
        <div className={`test ${isCollapsed ? "collapsed" : "open"}`}>
          <Header title={"Tests"} />
          <div className="head">
            <div>
              <Segmented
                defaultValue={TabStatus.ALL}
                onChange={handleTabChange}
                options={[TabStatus.ALL, TabStatus.ACTIVE, TabStatus.INACTIVE]}
              />
            </div>
            <div className="head-right">
              <Input
                className="antd-search-input-custom"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <Button
                type="primary"
                className="button"
                onClick={handleAddTest}
              >
                Add Test
              </Button>
            </div>
          </div>
          <div className="table">
            <Table
              dataSource={testData || []}
              columns={columns}
              rowKey={(record) => record._id}
              loading={!testData || isLoader}
              pagination={{
                current: paginationData?.currentPage,
                pageSize: paginationData?.pageSize,
                total: paginationData?.totalCount || 0, 
                onChange: handleTableChange,
              }}
              scroll={{ y: '61dvh' }}
            />
          </div>
        </div>
      ) : (
        <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>
      )}
      <TestModal
        isViewing={isViewing}
        isModalOpen={isModalOpen}
        isAddTest={isAddTest}
        isEditTest={isEditTest}
        selectedTest={selectedTest}
        questionData = {questionData?.filter(item => !usedQuestions?.includes(item))}
        candidates={candidateData}
        inputValue={inputValue}
        selectedIds={selectedIds}
        sectionData={sectionData}
        organizationData={organizationData}
        instituteData={instituteData}
        debouncedgetAllQuestion={debouncedgetAllQuestion}
        currentStage={currentStage}
        setCurrentStage={setCurrentStage}
        handleModalToggle={handleModalToggle}
        handleSubmit={handleSubmit}
        handleSectionAdd={handleSectionAdd}
        handleSectionChange={handleSectionChange}
        handleDeleteSection={renderLabelWithDelete}
        addDataToSection={addDataToSection}
        handleCheckboxChange={handleCheckboxChange}
        setSelectedSection={setSelectedSection}
        handleIndividualQuestion={handleIndividualQuestion}
      />
    </div>
  );
};

export default Test;