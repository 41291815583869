import { emailInterface, loginInterface } from "../constants/interfaces/auth";
import createAxiosInstance from "../tools/axiosInstance";

const authAxios = createAxiosInstance('AUTH');

export const loginService = async (url: string ,payload: loginInterface) => {
    try {
        const response = await authAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
} 

export const sendOtpService = async (url: string, payload: emailInterface) => {
    try {
        const response = await authAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const logoutService = async (url: string) => {
    try {
        const response = await authAxios.get(url);
        return response?.data;
    } catch (err) {}
}