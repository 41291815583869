import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TagState } from "../../constants/interfaces/tag";
import { PaginationInterface } from "../../constants/interfaces/common";

const initialState: TagState = {
    tagData: null,
    isCollapsed: false,
    isModalOpen: false,
    selectedTag: null,
    isAddTag: false,
    paginationData: undefined,
  };
  
  const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
      setTags: (state, action: PayloadAction<any[]>) => {
        state.tagData = action.payload;
      },
      toggleCollapsed: (state, action: PayloadAction<boolean>) => {
        state.isCollapsed = action.payload;
      },
      toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
        state.isModalOpen =  action.payload;
      },
      setSelectedTag: (state, action: PayloadAction<any | null>) => {
        state.selectedTag = action.payload;
      },
      removeTag: (state, action: PayloadAction<string>) => {
        state.tagData = state.tagData?.filter(
          (tag) => tag._id !== action.payload
        );
      },
      setIsAddTag: (state, action: PayloadAction<boolean>) => {
        state.isAddTag = action.payload;
      },
      setPagination: (state, action: PayloadAction<PaginationInterface>) => {
        state.paginationData = action.payload;
      }
    },
  });
  
  export const { toggleCollapsed, toggleIsModalOpen, setSelectedTag, removeTag, setIsAddTag, setTags, setPagination } = tagsSlice.actions;
  
  export default tagsSlice.reducer;