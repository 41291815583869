import { ParamObj } from "../constants/interfaces/common";
import { InstituteInterface } from "../constants/interfaces/institute";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const instituteAxios = createAxiosInstance('INSTITUTE');

export const getAllInstituteService = async (url: string, payload: ParamObj) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await instituteAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteInstituteService = async (url: string) => {
    try {
        const response = await instituteAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const addInstituteService = async (url: string, payload: InstituteInterface) => {
    try {
        const response = await instituteAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}