// src/routes/AppRoutes.tsx

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './pages/main-layout/mainlayout';
import Dashboard from './pages/admin-dashboard/dashboard';
import Login from './pages/login/login';
import Test from './pages/test/test';
import Question from './pages/question/question';
import Report from './pages/report/report';
import Organization from './pages/organization/organization';
import Institute from './pages/institute/institute';
import Setting from './pages/setting/setting';
import PrivateRoute from './privateRoute';
import Candidate from './pages/candidate/candidate';
import Tags from './pages/tag/tag';
import CandidateTests from './pages/candidate-test/candidateTest';
import { Roles } from './constants/enums/enums';
import UserProfile from './pages/profile/profile';
import FullScreenApp from './pages/full-screen-test/fullScreenTest';
import ThankYou from './pages/thankyou/thankyou';
import { useSelector } from 'react-redux';

const getRedirectPath = (role: Roles) => {
  switch (role) {
    case Roles.SUPER_ADMIN:
      return '/dashboard';
    case Roles.ORG_ADMIN:
    case Roles.INS_ADMIN:
      return '/dashboard';
    case Roles.CANDIDATE:
      return '/c/tests';
    default:
      return '/login';
  }
};

const AppRoutes: React.FC = () => {
  const { isLoggedIn, user } = useSelector((state: any) => state.auth);
  return (
    <Router>
      <Routes>

      {/* Unwrapped routes */}
      <Route
          path="/login"
          element={
            isLoggedIn ? <Navigate to={getRedirectPath(user?.role)} replace /> : <Login />
          }
        />
        <Route
          path="/"
          element={
            isLoggedIn ? <Navigate to={getRedirectPath(user?.role)} replace /> : <Login />
          }
        />

        {/* Wrapped routes */}
        <Route
          path="*"
          element={
            <MainLayout>
              <Routes>
                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} allowedRoles={[Roles.SUPER_ADMIN, Roles.ORG_ADMIN, Roles.INS_ADMIN]}/>} />
                <Route path="/tests" element={<PrivateRoute component={Test} allowedRoles={[Roles.SUPER_ADMIN]} />} />
                <Route path="/questions" element={<PrivateRoute component={Question} allowedRoles={[Roles.SUPER_ADMIN]}/>}/>
                <Route path="/reports" element={<PrivateRoute component={Report} allowedRoles={[Roles.SUPER_ADMIN, Roles.ORG_ADMIN, Roles.INS_ADMIN]}/>}/>
                <Route path="/organizations" element={<PrivateRoute component={Organization} allowedRoles={[Roles.SUPER_ADMIN]}/>} />
                <Route path="/institutes" element={<PrivateRoute component={Institute} allowedRoles={[Roles.SUPER_ADMIN]}/>}/>
                <Route path="/settings" element={<PrivateRoute component={Setting} allowedRoles={[Roles.SUPER_ADMIN, Roles.ORG_ADMIN, Roles.INS_ADMIN]}/>} />
                <Route path="/candidates" element={<PrivateRoute component={Candidate} allowedRoles={[Roles.SUPER_ADMIN, Roles.ORG_ADMIN, Roles.INS_ADMIN]}/>} />
                <Route path="/tags" element={<PrivateRoute component={Tags}allowedRoles={[Roles.SUPER_ADMIN]}/>} />
                <Route path="/c/tests" element={<PrivateRoute component={CandidateTests} allowedRoles={[Roles.CANDIDATE]}/>} />
                <Route path="/c/profile" element={<PrivateRoute component={UserProfile} allowedRoles={[Roles.CANDIDATE]}/>} />
              </Routes>
            </MainLayout>
          }
        />
        <Route path="/c/test-session" element={<PrivateRoute component={FullScreenApp} allowedRoles={[Roles.CANDIDATE]}/>} />
        <Route path="/c/complete" element={<PrivateRoute component={ThankYou} allowedRoles={[Roles.CANDIDATE]}/>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;