import { CandidateInterface } from "../constants/interfaces/candidate";
import { ParamObj } from "../constants/interfaces/common";
import { UserInterface } from "../constants/interfaces/user";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const candidateAxios = createAxiosInstance('CANDIDATE');

export const addCandidateService = async (url: string, payload: CandidateInterface) => {
    try {
        const response = await candidateAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const bulkAddCandidateService = async (url: string, payload: any) => {
    try {
        const response = await candidateAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const getAllCandidateService = async (url: string, paramObj: ParamObj) => {
    try {
        let getUrl = url;
        if (paramObj) getUrl = `${url}${makeQueryParam(paramObj)}`;
        const response = await candidateAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteCandidateService = async (url: string) => {
    try {
        const response = await candidateAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const updateCandidateService = async (url: string, payload: UserInterface) => {
    try {
        const response = await candidateAxios.patch(url, payload);
        return response?.data;
    } catch (err) {}
}