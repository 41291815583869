import React from "react";
import { Modal, UploadProps } from "antd";
import CandidateForm from "../../Forms/CandidateForm/candidateForm";
import { CandidateModalProps } from "../../../constants/interfaces/candidate";
import { RcFile } from "antd/es/upload/interface";

const CandidateModal: React.FC<CandidateModalProps> = ({
  isViewing,
  isModalOpen,
  isBulkUploading,
  isAddCandidate,
  selectedCandidate,
  organizations,
  institutes,
  handleModalToggle,
  handleSubmit,
}) => {
  const uploadProps: UploadProps = {
    name: "file",
    accept: ".csv",
    beforeUpload: (file: RcFile) => {
      return false;
    },
    multiple: false,
    listType: "text",
    maxCount: 1,
  };

  return (
    <Modal
      title={
        isViewing
          ? `Candidate Details - ${selectedCandidate?.firstName} ${selectedCandidate?.lastName}`
          : "Add New Candidate"
      }
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
    >
      {isViewing && (
        <div className="candidate-details">
          <p>
            <strong>First Name:</strong> {selectedCandidate?.firstName}
          </p>
          <p>
            <strong>Last Name:</strong> {selectedCandidate?.lastName}
          </p>
          <p>
            <strong>Email:</strong> {selectedCandidate?.email}
          </p>
          <p>
            <strong>Phone:</strong> {selectedCandidate?.phone}
          </p>
          {selectedCandidate?.organization && (
            <p>
              <strong>Organization:</strong>{" "}
              {selectedCandidate?.organization?.name}
            </p>
          )}
          {selectedCandidate?.institute && (
            <p>
              <strong>Institute:</strong> {selectedCandidate?.institute?.name}
            </p>
          )}
          {selectedCandidate?.skills && selectedCandidate.skills.length > 0 && (
            <p>
              <strong>Skills:</strong> {selectedCandidate.skills.map((skill, index) => 
                {
                  if (selectedCandidate?.skills?.length  && (selectedCandidate?.skills?.length - 1 === index)) {
                    return skill.name;
                  } else {
                    return skill.name + ',';
                  } 
                })}
            </p>
          )}
          {selectedCandidate?.address && (
            <p>
              <strong>Address:</strong> {selectedCandidate?.address}
            </p>
          )}
        </div>
      )}

      {(isAddCandidate || isBulkUploading) && (
        <CandidateForm
          onSubmit={handleSubmit}
          onCancel={handleModalToggle}
          organizations={organizations}
          institutes={institutes}
          isBulkUploading={isBulkUploading}
          uploadProps={uploadProps}
        />
      )}
    </Modal>
  );
};

export default CandidateModal;