import { ParamObj } from "../constants/interfaces/common";
import { QuestionInterface } from "../constants/interfaces/question";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const questionAxios = createAxiosInstance('QUESTION');

export const getAllQuestionService = async (url: string, payload?: ParamObj) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await questionAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteQuestionService = async (url: string) => {
    try {
        const response = await questionAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const addQuestionService = async (url: string, payload: QuestionInterface) => {
    try {
        const response = await questionAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}

export const editQuestionService = async (url: string, payload: QuestionInterface) => {
    try {
        const response = await questionAxios.patch(url, {quesTags: payload.quesTags});
        return response?.data;
    } catch (err) {}
}

