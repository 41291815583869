import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationInterface } from '../../constants/interfaces/organization';
import { InstituteInterface } from '../../constants/interfaces/institute';
import { CandidatesState } from '../../constants/interfaces/candidate';
import { PaginationInterface } from '../../constants/interfaces/common';

const initialState: CandidatesState = {
  candidateData: null,
  isCollapsed: false,
  isModalOpen: false,
  selectedCandidate: null,
  isViewing: false,
  organizations: [],
  isBulkUploading: false,
  isAddCandidate: false,
  institutes: [],
  paginationData: undefined,
};

const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    setCandidates: (state, action: PayloadAction<any[]>) => {
      state.candidateData = action.payload;
    },
    toggleCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload;
    },
    toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen =  action.payload;
    },
    setSelectedCandidate: (state, action: PayloadAction<any | null>) => {
      state.selectedCandidate = action.payload;
    },
    setIsViewing: (state, action: PayloadAction<boolean>) => {
      state.isViewing = action.payload;
    },
    removeCandidate: (state, action: PayloadAction<string>) => {
      state.candidateData = state.candidateData?.filter(
        (candidate) => candidate._id !== action.payload
      );
    },
    setOrganizations: (state, action: PayloadAction<OrganizationInterface[]>) => {
      state.organizations = action.payload;
    },
    setBulkUploading: (state, action: PayloadAction<boolean>) => {
      state.isBulkUploading = action.payload;
    },
    setIsAddCandidate: (state, action: PayloadAction<boolean>) => {
      state.isAddCandidate = action.payload;
    },
    setInstitutes: (state, action: PayloadAction<InstituteInterface[]>) => {
      state.institutes = action.payload;
    },
    setPagination: (state, action: PayloadAction<PaginationInterface>) => {
      state.paginationData = action.payload;
    }
  },
});

export const { setCandidates, toggleCollapsed, toggleIsModalOpen, setSelectedCandidate, setIsViewing, removeCandidate, setOrganizations, setIsAddCandidate, setBulkUploading, setInstitutes, setPagination } = candidatesSlice.actions;

export default candidatesSlice.reducer;