import { useLocation, useNavigate } from "react-router-dom";

const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const updateQueryParam = (key: string, value: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(key, value);

    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true }
    );
  };

  const removeQueryParam = (key: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(key);

    navigate(
      {
        pathname: location.pathname,
        search: queryParams.toString(),
      },
      { replace: true }
    );
  };

  const getQueryParam = (key: string): string | null => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(key);
  };

  return { updateQueryParam, removeQueryParam, getQueryParam };
};

export default useQueryParams;
