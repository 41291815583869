import React from "react";
import { Modal } from "antd";
import { OrganizationModalProps } from "../../../constants/interfaces/organization";
import OrganizationForm from "../../Forms/OrganizationForm/organizationForm";

const OrganizationModal: React.FC<OrganizationModalProps> = ({
  isViewing,
  isModalOpen,
  isAddOrganization,
  selectedOrganization,
  handleModalToggle,
  handleSubmit,
}) => {

  return (
    <Modal
      title={
        isViewing
          ? `Organization Details - ${selectedOrganization?.name}`
          : "Add New Organization"
      }
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
    >
      {isViewing && (
        <div className="organization-details">
          <p>
            <strong>Name:</strong> {selectedOrganization?.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedOrganization?.email}
          </p>
          <p>
            <strong>Phone:</strong> {selectedOrganization?.phone}
          </p>
          <p>
            <strong>Employee Count:</strong> {selectedOrganization?.employeeCount}
          </p>
          {selectedOrganization?.address && (
            <p>
              <strong>Address:</strong> {selectedOrganization?.address?.address}
            </p>
          )}
        </div>
      )}

      {(isAddOrganization) && (
        <OrganizationForm
          onSubmit={handleSubmit}
          onCancel={handleModalToggle}
        />
      )}
    </Modal>
  );
};

export default OrganizationModal;