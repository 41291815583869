import React, { useEffect, useState } from "react";
import "./_dashboard.scss";
import Header from "../../components/Header/Header";
import { Card } from "antd";

const Dashboard: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1130);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 1130);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`dashboard ${isCollapsed ? "collapsed" : "open"}`}>
      <div className="main-content">
        <Header title={"Dashboard"} />

        <div className="card-parent">
          <Card title="Total Organizations" bordered={false} className="card">
            <p>500</p>
          </Card>
          <Card title="Total Institutes" bordered={false} className="card">
            <p>500</p>
          </Card>
          <Card title="Total Employees" bordered={false} className="card">
            <p>500</p>
          </Card>
          <Card title="Total Students" bordered={false} className="card">
            <p>500</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
