import React, { useState, ReactElement, cloneElement } from 'react';
import PrimarySidebar from '../../components/PrimarySidebar/PrimarySidebar';
import './_mainlayout.scss';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const enhancedChildren = React.Children.map(children, child => 
    React.isValidElement(child)
      ? cloneElement(child as ReactElement<any>, { collapsed, setCollapsed })
      : child
  );

  return (
    <div className="main-layout">
      <PrimarySidebar />
      <div className="content">
        {enhancedChildren}
      </div>
    </div>
  );
};

export default MainLayout;