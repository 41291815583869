import React, { useEffect, useState } from "react";
import "./_header.scss";
import moment from "moment";
import { HeaderProps } from "../../constants/interfaces/common";
import ThemeToggleButton from "../ThemeToggle/ThemeToggleButton";

const Header: React.FC<HeaderProps> = ({ title }) => {
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1130);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 1130);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <div className={isCollapsed ? "width-collapsed header" : "header width"}>
      <div className="header-left">
        <h2>{title}</h2>
        <div className="sub-heading">{moment().format("Do MMM YYYY")}</div>
      </div>
      <div className="header-right">
        <ThemeToggleButton active={theme} setTheme={setTheme} />
      </div>
    </div>
  );
};

export default Header;