import React from "react";
import { Modal } from "antd";
import { TagModalProps } from "../../../constants/interfaces/tag";
import TagForm from "../../Forms/TagForm/tagForm";

const TagModal: React.FC<TagModalProps> = ({
  isModalOpen,
  isAddTag,
  handleModalToggle,
  handleSubmit,
  tagData
}) => {

  return (
    <Modal
      title="Add New Tag"
      open={isModalOpen}
      onCancel={handleModalToggle}
      footer={null}
      centered={true}
    >

      {(isAddTag) && (
        <TagForm
          onSubmit={handleSubmit}
          onCancel={handleModalToggle}
          tagData={tagData}
        />
      )}
    </Modal>
  );
};

export default TagModal;