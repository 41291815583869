import React, { useEffect, useState } from "react";
import './_setting.scss';
import Header from "../../components/Header/Header";

const Setting: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 1130);

    useEffect(() => {
      const handleResize = () => {
        setIsCollapsed(window.innerWidth < 1130);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
        <div className={`setting ${isCollapsed ? "collapsed" : "open"}`}>
            <Header title={"Settings"}/>
        </div>
    );
}

export default Setting;