import React, { useContext } from 'react';
import { ThemeContext } from '../../context/theme';
import sunIcon from "../../assets/icons/sun.png";
import moonIcon from "../../assets/icons/moon.png";
import './_themeToggle.scss';
import { ThemeToggleButtonProps } from '../../constants/interfaces/common';

const ThemeToggleButton: React.FC<ThemeToggleButtonProps> = ({ active, setTheme }) => {
  const { toggleTheme } = useContext(ThemeContext);

  const handleThemeChange = (selectedTheme: string) => {
    if (active === selectedTheme) return;
    setTheme && setTheme(selectedTheme);
    toggleTheme();
  };

  return (
    <div className="theme">
      <div
        className={`inner-radio ${active === 'light' ? 'light' : ''}`}
        onClick={() => handleThemeChange("light")}
      >
        <img src={sunIcon} className="radio-icon" alt="Sun Icon" />
      </div>
      <div
        className={`inner-radio ${active === 'dark' ? 'dark' : ''}`}
        onClick={() => handleThemeChange("dark")}
      >
        <img src={moonIcon} className="radio-icon" alt="Moon Icon" />
      </div>
    </div>
  );
};

export default ThemeToggleButton;