import React from "react";
// import ThemeToggleButton from "../../ThemeToggle/ThemeToggleButton";
import logo from "../../../assets/icons/LogoDarkWihoutBG.svg";
// import logoWhite from "../../../assets/icons/LogoWhiteWihoutBg.svg";
import './_instructionHeader.scss';

const InstructionHeader: React.FC = () => {
  // const [active, setTheme] = useState(localStorage.getItem("theme") || "light");

  // Sync theme change with localStorage
  // useEffect(() => {
  //   localStorage.setItem("theme", active);
  // }, [active]);

  return (
    <div className="instruction-header">
      <div className="logo">
        {/* {active === "light" ? ( */}
          <img src={logo} alt="Abc" className="main-logo" />
        {/* // ) : (
          <img src={logoWhite} alt="Abc" className="main-logo" />
        )} */}
      </div>
      {/* <div className="theme-button">
        <ThemeToggleButton active={active} setTheme={setTheme} />
      </div> */}
    </div>
  );
};

export default InstructionHeader;