import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Header from "../../components/Header/Header";
import { Segmented, Pagination, Spin, Empty, message } from "antd";
import { Roles, TestStatus } from "../../constants/enums/enums";
import useQueryParams from "../../utils/useQueryParams";
import "./_candidateTest.scss";
import { debounce } from "lodash";
import {
  getAllCandidateTestService,
  getCandiateTestService,
} from "../../services/test";
import {
  setCandidateTestData,
  setPagination,
  toggleCollapsed,
} from "../../redux/slice/candidateTest";
import {
  GET_ALL_EXECUTED_TESTS_CANDIDATE,
  GET_ALL_PENDING_TESTS_CANDIDATE,
  GET_TEST_CANDIDATE,
} from "../../constants/endpoints/test";
import PendingCard from "../../components/Cards/PendingTestCard";
import { useNavigate } from "react-router-dom";
import TestInstructionModal from "../../components/Modals/TestModal/TestInstructionModal/testInstructionModal";
import { TestInterface } from "../../constants/interfaces/test";
import { detectIncognitoMode, getAllDeviceDetails } from "../../utils/helpers";

const CandidateTests: React.FC = () => {
  const [isLoader, setIsLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInstructionModal, setIsInstructionModal] = useState(false);
  const [testData, setTestData] = useState<TestInterface | null>(null);
  const [pageSize] = useState(9);
  const dispatch = useDispatch();
  const apiCalled = useRef(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { removeQueryParam, getQueryParam } = useQueryParams();
  const { isCollapsed, candidateTestData, paginationData } = useSelector(
    (state: RootState) => state.candidateTests
  );
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllCandidateTests = useCallback(
    debounce(
      async (
        q = "",
        selectedTab = TestStatus.PENDING,
        page = 1,
        limit = 9,
        isUpdated = false
      ) => {
        try {
          setIsLoader(true);
          const pageQuery = getQueryParam("page");
          const currentPage = isUpdated
            ? page
            : parseInt(pageQuery ? pageQuery : "1");

          let paramObj = {
            sortOrder: -1,
            ...(q && q !== ""
              ? { q }
              : {
                  paginate: true,
                  page: currentPage,
                  limit,
                }),
          };

          const response =
            selectedTab === TestStatus.PENDING
              ? await getAllCandidateTestService(
                  GET_ALL_PENDING_TESTS_CANDIDATE,
                  paramObj
                )
              : await getAllCandidateTestService(
                  GET_ALL_EXECUTED_TESTS_CANDIDATE,
                  paramObj
                );

          if (response.success) {
            dispatch(setCandidateTestData(response?.data?.data));
            dispatch(
              setPagination({
                totalCount: response?.data?.pagination?.totalCount,
                currentPage,
                pageSize: limit,
              })
            );
          }
          setIsLoader(false);
        } catch (err) {
          setIsLoader(false);
        }
      },
      500
    ),
    [dispatch]
  );

  useEffect(() => {
    if (!apiCalled.current && user?.role === Roles.CANDIDATE) {
      debouncedGetAllCandidateTests(
        "",
        TestStatus.PENDING,
        currentPage,
        pageSize
      );
      apiCalled.current = true;
    }
  }, [user, dispatch, debouncedGetAllCandidateTests, currentPage, pageSize]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const handleTabChange = (key: string) => {
    removeQueryParam("page");
    setCurrentPage(1);
    debouncedGetAllCandidateTests("", key, 1, pageSize, true);
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
    debouncedGetAllCandidateTests("", TestStatus.PENDING, page, pageSize, true);
  };

  const handleViewInstructionClick = async (id: string) => {
    await getTestDetails(id);
    setIsInstructionModal(true);
  };

  const getTestDetails = async (id: string) => {
    try {
      if (!id) {
        navigate("/c/tests");
        return;
      }
      const response = await getCandiateTestService(
        GET_TEST_CANDIDATE.replace(":id", id as string),
        {}
      );
      if (response?.success && response?.data) {
        setTestData({...response?.data, _id: id});
        return {...response?.data, _id: id};
      }
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };

  const handleTestStart = async (id: string) => {
    const deviceDetails = await getAllDeviceDetails();
    if (deviceDetails?.browserInfo?.name !== "Chrome") {
      return message.error("Requirements Not Met! Please use Chrome Browser!");
    }
    const isPrivate = await detectIncognitoMode();
    if (isPrivate) {
      return message.error("Requirements Not Met! Do not use incognito mode.");
    }

    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    } catch (error) {
      return message.error(
        "Access to camera and microphone is required. Please allow access and try again."
      );
    }

    const data = await getTestDetails(id);
    localStorage.setItem('testData', JSON.stringify(data));
    window.open("/c/test-session");
  };

  return (
    <div className={`candidate-test ${isCollapsed ? "collapsed" : "open"}`}>
      <Header user={user ? user : {}} title="Tests" />
      <div className="head">
        <Segmented
          defaultValue={TestStatus.PENDING}
          onChange={handleTabChange}
          options={[TestStatus.PENDING, TestStatus.COMPLETED]}
        />
      </div>

      {isLoader ? (
        <div className="spinner">
          <Spin className="center" />
        </div>
      ) : (
        <div>
          <div className="test-cards-grid">
            {candidateTestData &&
              candidateTestData.map((testData, index) => (
                <PendingCard
                  key={testData._id || index}
                  data={testData}
                  handleViewInstructionClick={handleViewInstructionClick}
                  onStart={handleTestStart}
                />
              ))}
          </div>

          {candidateTestData && candidateTestData?.length > 0 ? (
            <div className="pagination-container">
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={paginationData?.totalCount}
                onChange={handlePaginationChange}
              />
            </div>
          ) : (
            <div className="spinner">
              <Empty />
            </div>
          )}
        </div>
      )}
      {isInstructionModal && (
        <TestInstructionModal
          openModal={isInstructionModal}
          onCancel={() => setIsInstructionModal(false)}
          testData={testData}
        />
      )}
    </div>
  );
};

export default CandidateTests;
