import React from "react";
import './_testInstructionCard.scss';
import { TestInstructionCardProps } from "../../../constants/interfaces/test";

const TestInstructionCard: React.FC<TestInstructionCardProps> = ({sections}) => {
  const formattedTime =
    sections?.reduce((acc, section) => acc + (section.totalTime || 0), 0) || 0;
  const displayTime = `${Math.floor(formattedTime / 3600) > 0 ? `${Math.floor(formattedTime / 3600)}hours ` : ""}${Math.floor((formattedTime % 3600) / 60)} minutes`;
    return (
        <div className="test-instruction-card">
            <div className="card-header">
                <h2>Good to Know!</h2>
            </div>
            <div className="instruction-section">
                <div className="instruction">
                    <div className="number">1</div>
                    <p>
                        Make sure that you will not be interrupted for <strong>{displayTime}</strong>.<br />
                        Once you click the <span className="highlight">START THE TEST</span> button, 
                        you will not be able to pause or retake the test.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">2</div>
                    <p>
                        You cannot go back to previous question, so make sure you are confident of your
                        answer before moving to the next question.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">3</div>
                    <p>
                        Ensure a stable internet connection throughout the test to avoid interruptions or losing progress.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">4</div>
                    <p>
                        Only use supported browser (Chrome) on a desktop or laptop computer. 
                        Avoid using mobile devices or tablets for a smooth test experience.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">5</div>
                    <p>
                        This is an individual assessment. No external help or unauthorized materials are allowed. You will be monitored via webcam and microphone throughout the test!
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">6</div>
                    <p>
                        Do not refresh or close the browser window during the test, as this might result in losing your progress.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">7</div>
                    <p>
                        Questions have individual time limits. Pay attention to the timer, and try to complete each question within the allotted time.
                    </p>
                </div>
                <div className="instruction">
                    <div className="number">8</div>
                    <p>
                        Contact support immediately if you experience technical difficulties during the test. Do not try to resolve technical issues on your own.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default TestInstructionCard;