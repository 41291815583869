import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TestInterface, TestState } from "../../constants/interfaces/test";
import { QuestionInterface } from "../../constants/interfaces/question";
import { CandidateInterface } from "../../constants/interfaces/candidate";
import { PaginationInterface } from "../../constants/interfaces/common";

const initialState: TestState = {
    testData: null,
    isCollapsed: false,
    selectedTest: null,
    questionData: [],
    usedQuestions: [],
    isModalOpen: false,
    isViewing: false,
    isAddTest: false,
    isEditTest: false,
    candidateData: null,
    sectionData: [],
    organizationData: [],
    instituteData: [],
    paginationData: undefined,
    executedTestData: [],
    selectedExecutedTest: null,
    executedTestPaginationData: undefined,
}

const testsSlice = createSlice({
    name: "tests",
    initialState,
    reducers: {
        setTestData: (state, action: PayloadAction<TestInterface[]>) => {
            state.testData = action.payload;
        },
        setSelectedTest: (state, action: PayloadAction<TestInterface | null>) => {
            state.selectedTest = action.payload;
        },
        toggleCollapsed: (state, action: PayloadAction<boolean>) => {
            state.isCollapsed = action.payload;
          },
        setQuestionData: (state, action: PayloadAction<QuestionInterface[]>) => {
            state.questionData = action.payload;
        },
        setUsedQuestions: (state, action: PayloadAction<QuestionInterface[]>) => {
            state.usedQuestions = action.payload;
        },
        removeTest: (state, action: PayloadAction<string>) => {
            state.testData = state.testData?.filter(
              (test) => test._id !== action.payload
            );
        },
        setIsViewing: (state, action: PayloadAction<boolean>) => {
            state.isViewing = action.payload;
        },
        toggleIsModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isModalOpen =  action.payload;
        },
        setIsAddTest: (state, action: PayloadAction<boolean>) => {
            state.isAddTest = action.payload;
        },
        setIsEditTest: (state, action: PayloadAction<boolean>) => {
            state.isEditTest = action.payload;
        },
        setCandidateData: (state, action: PayloadAction<CandidateInterface[] | null>) => {
            state.candidateData = action.payload;
        },
        setSectionData: (state, action: PayloadAction<any>) => {
            state.sectionData = action.payload;
        },
        setOrganizationData: (state, action: PayloadAction<any>) => {
            state.organizationData = action.payload;
        },
        setInstituteData: (state, action: PayloadAction<any>) => {
            state.instituteData = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginationInterface>) => {
            state.paginationData = action.payload;
        },
        setExecutedTestPagination: (state, action: PayloadAction<PaginationInterface>) => {
            state.executedTestPaginationData = action.payload;
        },
        setExecutedTestData: (state, action: PayloadAction<TestInterface[]>) => {
            state.executedTestData = action.payload;
        },
        setExecutedSelectedTest: (state, action: PayloadAction<TestInterface | null>) => {
            state.selectedExecutedTest = action.payload;
        },
    }
})

export const {setTestData, setSelectedTest, toggleCollapsed, removeTest, setIsViewing, 
    toggleIsModalOpen, setIsAddTest, setIsEditTest, setQuestionData, setCandidateData, 
    setSectionData, setOrganizationData, setInstituteData, setPagination, setExecutedTestPagination, 
    setExecutedTestData, setExecutedSelectedTest, setUsedQuestions} = testsSlice.actions;

export default testsSlice.reducer;