import React, { useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import "./_tagForm.scss";
import { TagFormProps } from "../../../constants/interfaces/tag";

const { Option } = Select;

const TagForm: React.FC<TagFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  tagData
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      onSubmit(values);
      form.resetFields();
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Form form={form} layout="vertical" className="candidate-form">
          <Form.Item
            label="Tag"
            name="quesTags"
            rules={[]}
          >
            <Select placeholder="Select tag">
              {tagData?.map((tag) => (
                <Option key={tag._id} value={tag._id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>

      <div className="form-actions">
        <Button className="cancel-button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" className="submit-button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default TagForm;
