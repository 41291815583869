import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import ProfileForm from "../../components/Forms/ProfileForm/profileForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { UserInterface } from "../../constants/interfaces/user";
import { debounce } from "lodash";
import { getAllTagService } from "../../services/tag";
import { GET_ALL_TAGS_CANDIDATE } from "../../constants/endpoints/tag";
import { setTags, toggleCollapsed } from "../../redux/slice/user";
import { Roles } from "../../constants/enums/enums";
import { updateCandidateService } from "../../services/candidate";
import { UPDATE_CANDIDATE_PROFILE } from "../../constants/endpoints/candidate";
import { Spin, message } from "antd";
import { loginSuccess } from "../../redux/slice/auth";
import './_profile.scss';
import UnauthorizedImage from "../../assets/images/unauthorized.jpg";

const UserProfile: React.FC = () => {
    const [isLoader, setIsLoader] = useState(true);
    const dispatch = useDispatch();
    const apiCalled = useRef(false);
    const { user } = useSelector((state: RootState) => state.auth);
    const {
        isCollapsed,
        tags
      } = useSelector((state: RootState) => state.users);
    

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetAllTags = useCallback(
    debounce(async () => {
      try {
        setIsLoader(true);

        let paramObj = {
          sortOrder: -1,
          isDeleted: false
        };
  
        const response = await getAllTagService(GET_ALL_TAGS_CANDIDATE, paramObj);
        if (response.success) {
            dispatch(setTags(response?.data));
        }
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
      }
    }, 1000),
    [dispatch]
  );

    
  useEffect(() => {
    if (
      !apiCalled.current &&
      (user?.role === Roles.CANDIDATE)
    ) {
      debouncedGetAllTags();
      apiCalled.current = true;
    }

    const handleResize = () => {
      if(window.innerWidth < 1130) {
        dispatch(toggleCollapsed(true));
      } else {
        dispatch(toggleCollapsed(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user, dispatch, debouncedGetAllTags]);

      
      
    const onSubmit = async (values: UserInterface) => {
        try {
            setIsLoader(true);
            const response = await updateCandidateService(UPDATE_CANDIDATE_PROFILE, values);
            if (response) {
                message.success('Profile updated successfully!');    
                dispatch(loginSuccess({ user: response?.data}));       
            }
            setIsLoader(false);
        } catch (err) {
            setIsLoader(false);
        }
    }

    return (
      <>
        {user?.role === Roles.CANDIDATE ? (<div className={`profile ${isCollapsed ? "collapsed" : "open"}`}>
            <Header title="Edit Profile" />
            <div className={`profile-body ${isLoader && 'loader'}`}>
                {isLoader ? <Spin className="center"/> : (<ProfileForm 
                onSubmit={onSubmit} 
                user={user}
                tags={tags}
                />)}
            </div>
            </div>) : <div className={`unauthorized ${isCollapsed ? "collapsed" : "open"}`}>
          <img src={UnauthorizedImage} alt="Unauthorized" />
        </div>}
    </>
    )
}

export default UserProfile;