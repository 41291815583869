import { ParamObj } from "../constants/interfaces/common";
import { OrganizationInterface } from "../constants/interfaces/organization";
import createAxiosInstance from "../tools/axiosInstance";
import { makeQueryParam } from "../utils/helpers";

const organizationAxios = createAxiosInstance('ORGANIZATION');

export const getAllOrganizaionService = async (url: string, payload?: ParamObj) => {
    try {
        let getUrl = url;
        if(payload) getUrl = `${url}${makeQueryParam(payload)}`
        const response = await organizationAxios.get(getUrl);
        return response?.data;
    } catch (err) {}
}

export const deleteOrganizationService = async (url: string) => {
    try {
        const response = await organizationAxios.delete(url);
        return response?.data;
    } catch (err) {}
}

export const addOrganizationService = async (url: string, payload: OrganizationInterface) => {
    try {
        const response = await organizationAxios.post(url, payload);
        return response?.data;
    } catch (err) {}
}