// src/App.tsx

import React from 'react';
import AppRoutes from './routes';
import './styles/global/style.scss';
import { ThemeProvider } from './context/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
